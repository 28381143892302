/* eslint-disable no-bitwise */
/* eslint-disable import/prefer-default-export */
import { ApiExceptionResponse } from 'api/types';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';

export const showException = (error: any) => {
  const exception = error?.response?.data as ApiExceptionResponse;
  if (exception.status === 401) {
    toast.error('Du är inte inloggad');
    return;
  }
  if (exception.status === 403) {
    toast.error('Du har inte behörighet att göra detta');
    return;
  }
  toast.error(exception?.title);
};
