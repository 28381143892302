import {
  AdvancedAssignmentFilterOption,
  AssignmentClient,
  CaseTypeEnum,
  GetAssignmentsRequest,
} from 'api';
import { pollersErrorModalId, refreshRate } from 'constants/AppConstants';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import useModalStack from 'contexts/modal/useModalStack';
import ApiErrorModalIds from 'contexts/ApiCallConfiguration/ApiErrorModalIds';
import assignmentsSearchFunction from 'utils/old_assignmentsSearchFunction';
import { useInterval } from 'usehooks-ts';
import { useIdle } from 'contexts/IdleContext/useIdle';

const useFilteredAssignments = (caseType: CaseTypeEnum) => {
  const { isIdle } = useIdle();
  const { pop: popModal } = useModalStack();
  const {
    state: {
      date,
      visibleStatuses,
      visibleBusinessUnits,
      advancedFilters,
      searchText,
      triggerAssignmentsUpdate,
    },
  } = useAssignmentsFilters();
  const location = useLocation();

  const assignmentsResponse = useApiCall(
    AssignmentClient,
    (
      c,
      type: CaseTypeEnum,
      dt: Date,
      advancedFilterOptions: AdvancedAssignmentFilterOption[],
      resetCache = false
    ) =>
      c.getList(
        new GetAssignmentsRequest({
          type,
          date: dt,
          advancedAssignmentFilterOptions: advancedFilterOptions,
          resetCache,
        })
      ),
    undefined,
    { errorModalId: pollersErrorModalId }
  );

  const update = useCallback(
    async (clearBeforeUpdating: boolean) => {
      if (clearBeforeUpdating) {
        assignmentsResponse.setStatus(RequestStatus.Idle);
        assignmentsResponse.setResponse(undefined);
      }

      const [, error] = await assignmentsResponse.run(
        caseType,
        date,
        Array.from(advancedFilters ?? new Set()),
        true
      );
      if (!error) {
        popModal(pollersErrorModalId);
        popModal(ApiErrorModalIds.ConnectionError);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [caseType, date, popModal, advancedFilters]
  );

  useEffect(() => {
    update(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseType, date, triggerAssignmentsUpdate, advancedFilters]);

  useInterval(
    () => {
      update(false);
    },
    !isIdle &&
      assignmentsResponse.status !== RequestStatus.Fetching && // Don't poll if we're already fetching
      assignmentsResponse.status !== RequestStatus.Error // Don't poll if we're in an error state
      ? refreshRate
      : null
  );

  const filteredAssignments = useMemo(
    () =>
      assignmentsSearchFunction(
        assignmentsResponse.response ?? [],
        searchText,
        visibleStatuses,
        visibleBusinessUnits,
        location.pathname
      ),
    [
      assignmentsResponse.response,
      location.pathname,
      searchText,
      visibleBusinessUnits,
      visibleStatuses,
    ]
  );

  return useMemo(
    () => ({
      filteredAssignments,

      status: assignmentsResponse.status,
      update,
    }),
    [assignmentsResponse.status, update, filteredAssignments]
  );
};

export default useFilteredAssignments;
