import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import {
  faCarAlt,
  faExternalLinkAlt,
  faHand,
  faPlus,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssignmentRequestsGetAssignmentRequestsForUserAndDate } from 'api/assignment-requests/assignment-requests';
import { useAssignmentGetBookedAssignmentsForUserAndDate } from 'api/assignment/assignment';
import {
  AssignmentDeviationEnum,
  AssignmentModel,
  AssignmentRequestModel,
  AssignmentStatusEnum,
  AssignmentTypeEnum,
  AssignmentViewModel,
  AvailabilityDto,
  CaseExtendedModel,
  CaseModel,
  CaseStatusEnum,
  CaseTypeEnum,
} from 'api/model';
import ContextWrappedCreateCaseModal from 'components/CreateCaseModal';
import Routes from 'constants/Routes';
import useCompanies from 'contexts/basicData/useCompanies';
import useTranslations from 'contexts/basicData/useTranslations';
import useModalStack from 'contexts/modal/useModalStack';
import React, { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { IAddress } from 'utils/address';
import { formatTime } from 'utils/date-helpers';
import Address from './Address';
import TextButton from 'components/inputs/TextButton';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { useCaseDeleteCase } from 'api/case/case';
import useUsers from 'contexts/basicData/useUsers';
import useZipCodeAreas from 'hooks/useZipCodeAreas';
import getPlaceholderIcon, {
  getLastAssignmentDetails,
} from './worktime-helper';
import useHasTestAccessToPlaceholderCases from 'hooks/useHasTestAccessToPlaceholderCases';
import { showException } from 'utils/exception-helper';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  gap: 0px;
`;

const Title = styled.h2`
  margin-top: 15px;
  font-size: ${({ theme }) => theme.sizes.font.medium};

  &:first-of-type {
    margin-top: 0;
  }
`;

const CaseButton = styled(TextButton)<{ strikeThrough?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  padding: 2px;
  width: 100%;
`;

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 100px 200px 1fr;
  align-items: center;
  padding: 5px;

  /* highlight row on hover */
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.secondary};
  }
`;

const RowActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  // sub-div should have border
  div {
    border: 1px solid ${({ theme }) => theme.colors.border.primary};
    border-radius: 3px;
    padding: 5px;
  }
  // sub-div should have border error color if className is error
  div.error {
    border-color: ${({ theme }) => theme.colors.foreground.error};
  }
  margin-bottom: 5px;
`;

const Label = styled.span<{ level?: 'info' | 'warning' | 'error' }>`
  color: ${({ theme, level = 'info' }) => {
    switch (level) {
      case 'warning':
        return theme.colors.foreground.warning;
      case 'error':
        return theme.colors.foreground.error;
      default:
        return theme.colors.foreground.primary;
    }
  }};
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 10px;
  user-select: none;
  align-items: center;
`;

interface DayDrivingTime {
  start: Date;
  end: Date;
  fromAddress: IAddress;
  toAddress: IAddress;
  assignmentRequest?: AssignmentRequestModel;
  assignment?: AssignmentViewModel;
  startsOutsideHomeCounty?: boolean;
  endsOutsideHomeCounty?: boolean;
}

const AssignmentRow: React.FC<{
  drivingTimes: DayDrivingTime;

  model: AssignmentViewModel;
}> = ({ model, drivingTimes }) => {
  const modalStack = useModalStack();
  const { assignmentTypes, caseTypes, assignmentDeviations } =
    useTranslations();

  const deleteCase = useCaseDeleteCase();

  const isCanceled = (
    [
      AssignmentDeviationEnum.Canceled,
      AssignmentDeviationEnum.Aborted,
    ] as AssignmentDeviationEnum[]
  ).includes(model.assignment.assignmentDeviationID);

  const handleDeletePlaceholder = () => {
    if (model.case.caseTypeID !== CaseTypeEnum.Placeholder) {
      return; // only placeholders can be deleted
    }

    modalStack.push(
      <ConfirmModal
        title="Ta bort utfyllnadsuppdrag"
        content="Är du säker på att du vill ta bort utfyllnadsuppdraget?"
        onConfirm={async () => {
          try {
            await deleteCase.mutateAsync({
              params: {
                caseId: model.case.caseID,
              },
            });
            // if we are currently on the case page or the case id, navigate up one level
            if (
              window.location.pathname ===
              `${Routes.placeholder.index}/${model.case.caseID.toString()}`
            ) {
              window.location.href = Routes.placeholder.index;
            } else if (
              window.location.pathname ===
              `${Routes.search.index}/${model.case.caseID.toString()}`
            ) {
              window.location.href = Routes.search.index;
            }
            modalStack.pop();
          } catch (error) {
            showException(error);
          }
        }}
        onCancel={() => {
          modalStack.pop();
        }}
      />
    );
  };
  return (
    <RowWrapper>
      <FontAwesomeIcon
        icon={
          model.case.caseTypeID === CaseTypeEnum.Placeholder
            ? getPlaceholderIcon(model.assignment.assignmentTypeID)
            : faCarAlt
        }
      />
      <span>{`${formatTime(drivingTimes.start)} - ${formatTime(drivingTimes.end)}`}</span>
      <span>
        {`${model.case.registrationNumber} (${
          caseTypes[model.case.caseTypeID]
        }, ${assignmentTypes[model.assignment.assignmentTypeID]}) ${
          isCanceled
            ? (assignmentDeviations[
                model.assignment.assignmentDeviationID
              ]?.toUpperCase() ?? '')
            : ''
        }`}
      </span>
      <RowActionWrapper>
        {model.case.caseTypeID === CaseTypeEnum.Placeholder && (
          <CaseButton onClick={handleDeletePlaceholder}>
            <FontAwesomeIcon style={{ marginLeft: 'auto' }} icon={faTrashCan} />
          </CaseButton>
        )}
        <CaseButton
          onClick={() => {
            window.open(
              `${Routes.search.index}/${model.case.caseID}`,
              model.case.caseID.toString() ?? '_blank'
            );
          }}
          strikeThrough={isCanceled}
        >
          <FontAwesomeIcon
            style={{ marginLeft: 'auto' }}
            icon={faExternalLinkAlt}
          />
        </CaseButton>
      </RowActionWrapper>
    </RowWrapper>
  );
};

const AssignmentRequestRow: React.FC<{
  drivingTimes: DayDrivingTime;
  model: AssignmentRequestModel;
}> = ({ model, drivingTimes }) => {
  const { companiesById } = useCompanies();
  return (
    <RowWrapper>
      <FontAwesomeIcon icon={faHand} title="Annonsförfrågan" />
      <span>{formatTime(drivingTimes.start)}</span>
      <span>
        Annonsförfrågan
        <br />
        <span>{companiesById.get(model.fromCompanyID)?.name}</span>
        <br />
        <span>{companiesById.get(model.toCompanyID)?.name}</span>
      </span>
      <CaseButton
        onClick={() => {
          window.open(
            `${Routes.internalDeliveryGroup.requests.index}/${model.requestID}`,
            model.requestID.toString() ?? '_blank'
          );
        }}
        unpadded
      >
        <FontAwesomeIcon
          style={{ marginLeft: 'auto' }}
          icon={faExternalLinkAlt}
        />
      </CaseButton>
    </RowWrapper>
  );
};

interface Props {
  availability: AvailabilityDto;
  userId: number;
}
const WorkTimeWidget: React.FC<Props> = ({ availability, userId }) => {
  const hasAccessToPlaceholderBookings = useHasTestAccessToPlaceholderCases();

  const { assignmentTypes } = useTranslations();
  const users = useUsers();
  const { zipCodeAreaFromZipCode } = useZipCodeAreas();

  const userCounty = useMemo(() => {
    const user = users[userId];
    if (user) {
      return zipCodeAreaFromZipCode(user.postalCode);
    }
    return undefined;
  }, [users, userId, zipCodeAreaFromZipCode]);

  const { companies } = useCompanies();
  const modalStack = useModalStack();

  const bookedAssignmentsCall =
    useAssignmentGetBookedAssignmentsForUserAndDate();

  const getBookedAssignments = async () => {
    try {
      await bookedAssignmentsCall.mutateAsync({
        params: {
          userId,
          date: availability.date,
        },
      });
      // setBookedAssignments(result);
    } catch (error) {
      toast.error('Kunde inte hämta bokade uppdrag');
    }
  };

  // fetch booked assignments on mount
  useEffect(
    () => {
      getBookedAssignments();
    } /* eslint-disable react-hooks/exhaustive-deps */,
    [availability.dateString]
  );

  //   const getStartToEndOfAssignmentList = (
  //     assignmentList: AssignmentViewModel[]
  //   ) => {
  //     // handle empty lists
  //     if (assignmentList.length === 0) {
  //       return '';
  //     }
  //     // find the start time of the first assignment
  //     const startTime =
  //       assignmentList[0].assignment.estimatedStartTime ??
  //       assignmentList[0].assignment.startTime;
  //     // find the end time of the last assignment
  //     const endStartTime =
  //       assignmentList[assignmentList.length - 1].assignment.estimatedStartTime ??
  //       assignmentList[assignmentList.length - 1].assignment.startTime;
  //     const endEstimatedDuration =
  //       assignmentList[assignmentList.length - 1].assignment.estimatedDuration;
  //     if (!endStartTime || endEstimatedDuration === undefined) {
  //       return '';
  //     }
  //     const endEndTime = new Date(endStartTime.getTime() + endEstimatedDuration);
  //     // print and return
  //     return `${formatTime(startTime)} - ${formatTime(endEndTime)}`;
  //   };

  const assignmentRequestsCall =
    useAssignmentRequestsGetAssignmentRequestsForUserAndDate({
      userId,
      date: availability.date,
    });

  const handleCreateMorningPlaceholder = () => {
    if (bookedAssignmentsCall.data?.length === 0) {
      return;
    }

    const bookedAssignments = bookedAssignmentsCall.data!;

    // set start time to 08:00
    const placeholderStartTime = new Date(availability.dateString);
    placeholderStartTime.setHours(8);
    placeholderStartTime.setMinutes(0);
    placeholderStartTime.setSeconds(0);
    placeholderStartTime.setMilliseconds(0);
    // set estimated duration (ms) to the amount of time between 08:00 and the first assignment's estimated start time
    const nextAssignmentStartTime =
      bookedAssignments[0].assignment.estimatedStartTime ??
      bookedAssignments[0].assignment.bookedTo;
    const placeholderEstimatedDuration =
      nextAssignmentStartTime.getTime() - placeholderStartTime.getTime();
    // set to address details to the first assignment's from address details
    const placeHolderToAddress: IAddress = {
      name: bookedAssignments[0].assignment.fromName,
      address: bookedAssignments[0].assignment.fromAddress,
      city: bookedAssignments[0].assignment.fromCity,
      zip: bookedAssignments[0].assignment.fromZip,
      county: bookedAssignments[0].assignment.fromCounty,
    };
    const assignedToUser = bookedAssignments[0].assignment.assignedTo;

    modalStack.push(
      <ContextWrappedCreateCaseModal
        goToCreated={false}
        onClose={() => {
          modalStack.pop();
        }}
        onCaseCreated={() => {
          modalStack.pop();
        }}
        newAssignmentOverrides={{
          lastPossibleEndTime: nextAssignmentStartTime,
        }}
        defaults={
          {
            case: {
              caseID: -1,
              caseStatusID: CaseStatusEnum.Open,
              caseTypeID: CaseTypeEnum.Placeholder,
              vehicleMileage: -1,
              assignmentList: [
                {
                  assignmentID: -1,
                  timestamp: '',
                  assignmentStatusID: AssignmentStatusEnum.Planned,
                  assignmentTypeID: AssignmentTypeEnum.CarPlaceholder,
                  assignedTo: assignedToUser,
                  bookedTo: placeholderStartTime,
                  estimatedStartTime: placeholderStartTime,
                  estimatedDuration: placeholderEstimatedDuration,
                  fromAddress: '',
                  fromCity: '',
                  fromZip: '',
                  toName: placeHolderToAddress.name,
                  toAddress: placeHolderToAddress.address,
                  toCity: placeHolderToAddress.city,
                  toZip: placeHolderToAddress.zip,
                  toCounty: placeHolderToAddress.county,
                } as AssignmentModel,
              ],
            } as CaseModel,
          } as CaseExtendedModel
        }
        caseType={CaseTypeEnum.Placeholder}
      />
    );
  };

  const handleCreateAfternoonPlaceholder = () => {
    const lastAssignmentDetails = getLastAssignmentDetails(
      bookedAssignmentsCall.data ?? []
    );
    if (!lastAssignmentDetails) {
      return;
    }

    const {
      // estimatedStartOfLastAssignment,
      placeholderStartTime,
      placeHolderToAddress,
      assignedToUser,
    } = lastAssignmentDetails;

    modalStack.push(
      <ContextWrappedCreateCaseModal
        goToCreated={false}
        onClose={() => {
          modalStack.pop();
        }}
        onCaseCreated={() => {
          modalStack.pop();
        }}
        newAssignmentOverrides={{
          firstPossibleStartTime: placeholderStartTime,
        }}
        defaults={
          {
            case: {
              caseID: -1,
              caseStatusID: CaseStatusEnum.Open,
              caseTypeID: CaseTypeEnum.Placeholder,
              vehicleMileage: -1,
              assignmentList: [
                {
                  assignmentID: -1,
                  timestamp: '',
                  assignmentStatusID: AssignmentStatusEnum.Planned,
                  assignmentTypeID: AssignmentTypeEnum.Normal,
                  assignedTo: assignedToUser,
                  bookedTo: placeholderStartTime,
                  estimatedStartTime: placeholderStartTime,
                  // estimatedDuration: placeholderEstimatedDuration,
                  fromName: placeHolderToAddress.name,
                  fromAddress: placeHolderToAddress.address,
                  fromCity: placeHolderToAddress.city,
                  fromZip: placeHolderToAddress.zip,
                  fromCounty: placeHolderToAddress.county,
                  toName: '',
                  toAddress: '',
                  toCity: '',
                  toZip: '',
                } as AssignmentModel,
              ],
            } as CaseModel,
          } as CaseExtendedModel
        }
        caseType={CaseTypeEnum.Placeholder}
      />
    );
  };

  const dayDrivingTimes: DayDrivingTime[] = useMemo(() => {
    const result: DayDrivingTime[] = [];

    // loop through booked assignments and add to result
    if (bookedAssignmentsCall.data) {
      bookedAssignmentsCall.data.forEach((assignment) => {
        const { estimatedDuration, endTime, startTime, estimatedStartTime } =
          assignment.assignment;
        const usedStart = startTime ?? estimatedStartTime;
        if (usedStart && estimatedDuration) {
          const usedEnd =
            endTime ?? new Date(usedStart.getTime() + estimatedDuration);
          result.push({
            start: usedStart,
            end: usedEnd,
            fromAddress: {
              name: assignment.assignment.fromName,
              address: assignment.assignment.fromAddress,
              city: assignment.assignment.fromCity,
              zip: assignment.assignment.fromZip,
              county: assignment.assignment.fromCounty,
            },
            toAddress: {
              name: assignment.assignment.toName,
              address: assignment.assignment.toAddress,
              city: assignment.assignment.toCity,
              zip: assignment.assignment.toZip,
              county: assignment.assignment.toCounty,
            },
            assignment,
            startsOutsideHomeCounty:
              assignment.assignment.fromCounty?.id !== userCounty?.id,
            endsOutsideHomeCounty:
              assignment.assignment.toCounty?.id !== userCounty?.id,
          });
        }
      });
    }
    // loop through assignment requests and splice into result
    if (assignmentRequestsCall.data) {
      assignmentRequestsCall.data
        .filter(
          (x) =>
            !bookedAssignmentsCall.data?.some((y) =>
              x.allocatedAssignments.some(
                (z) => z.assignment.assignmentID === y.assignment.assignmentID
              )
            )
        )
        .forEach((assignmentRequest) => {
          const fromCompany = companies.find(
            (c) => c.companyID === assignmentRequest.fromCompanyID
          );
          const toCompany = companies.find(
            (c) => c.companyID === assignmentRequest.toCompanyID
          );
          result.push({
            start: assignmentRequest.targetStartTime,
            end: assignmentRequest.targetEndTime,
            fromAddress: {
              name: fromCompany?.name ?? '-',
              address: fromCompany?.address ?? '-',
              city: fromCompany?.city ?? '-',
              zip: fromCompany?.zip ?? '-',
            },
            toAddress: {
              name: toCompany?.name ?? '-',
              address: toCompany?.address ?? '-',
              city: toCompany?.city ?? '-',
              zip: toCompany?.zip ?? '-',
            },
            assignmentRequest,
          });
        });
    }

    // sort the result by start time
    result.sort((a, b) => a.start.getTime() - b.start.getTime());

    return result;
  }, [
    bookedAssignmentsCall.data,
    assignmentRequestsCall.data,
    companies,
    userCounty,
  ]);

  const firstAssignmentOfDay = dayDrivingTimes[0];
  const lastAssignmentOfDay = dayDrivingTimes[dayDrivingTimes.length - 1];

  const startToEndTimeStr = useMemo(() => {
    if (!firstAssignmentOfDay || !lastAssignmentOfDay) {
      return '';
    }
    return `${formatTime(firstAssignmentOfDay.start)} - ${formatTime(
      lastAssignmentOfDay.end
    )}`;
  }, [firstAssignmentOfDay, lastAssignmentOfDay]);
  return (
    <Wrapper>
      <hr />
      <Title style={{ marginTop: 5, marginBottom: 5 }}>
        Arbetsdag: {startToEndTimeStr}
      </Title>
      <AddressWrapper>
        <span style={{ flex: 1 }}>
          <Address
            className={
              firstAssignmentOfDay?.startsOutsideHomeCounty ? 'error' : ''
            }
            title={`Startar ${firstAssignmentOfDay?.assignment?.case?.registrationNumber ? `(${firstAssignmentOfDay?.assignment?.case?.registrationNumber})` : ''}`}
            name={firstAssignmentOfDay?.assignment?.assignment.fromName ?? '-'}
            address={
              firstAssignmentOfDay?.assignment?.assignment.fromAddress ?? '-'
            }
            city={firstAssignmentOfDay?.assignment?.assignment.fromCity ?? '-'}
            zip={firstAssignmentOfDay?.assignment?.assignment.fromZip ?? '-'}
            county={firstAssignmentOfDay?.assignment?.assignment.fromCounty}
          >
            {firstAssignmentOfDay?.startsOutsideHomeCounty && (
              <Label level="error">
                <FontAwesomeIcon icon={faWarning} /> Utanför hemlän
              </Label>
            )}
            {firstAssignmentOfDay?.assignment?.case.caseTypeID ===
              CaseTypeEnum.Placeholder && (
              <Label level="warning">
                <FontAwesomeIcon
                  icon={getPlaceholderIcon(
                    firstAssignmentOfDay.assignment.assignment.assignmentTypeID
                  )}
                />{' '}
                {
                  assignmentTypes[
                    firstAssignmentOfDay.assignment.assignment.assignmentTypeID
                  ]
                }
              </Label>
            )}
          </Address>
        </span>
        <span style={{ flex: 1 }}>
          <Address
            className={
              lastAssignmentOfDay?.endsOutsideHomeCounty ? 'error' : ''
            }
            title={`Slutar ${lastAssignmentOfDay?.assignment?.case?.registrationNumber ? `(${lastAssignmentOfDay?.assignment?.case?.registrationNumber})` : ''}`}
            name={lastAssignmentOfDay?.assignment?.assignment.toName ?? '-'}
            address={
              lastAssignmentOfDay?.assignment?.assignment.toAddress ?? '-'
            }
            city={lastAssignmentOfDay?.assignment?.assignment.toCity ?? '-'}
            zip={lastAssignmentOfDay?.assignment?.assignment.toZip ?? '-'}
            county={lastAssignmentOfDay?.assignment?.assignment.toCounty}
          >
            {lastAssignmentOfDay?.endsOutsideHomeCounty && (
              <Label level="error">
                <FontAwesomeIcon icon={faWarning} /> Utanför hemlän
              </Label>
            )}
            {lastAssignmentOfDay?.assignment?.case.caseTypeID ===
              CaseTypeEnum.Placeholder && (
              <Label level="warning">
                <FontAwesomeIcon
                  icon={getPlaceholderIcon(
                    lastAssignmentOfDay.assignment.assignment.assignmentTypeID
                  )}
                />{' '}
                {
                  assignmentTypes[
                    lastAssignmentOfDay.assignment.assignment.assignmentTypeID
                  ]
                }
              </Label>
            )}
          </Address>
        </span>
      </AddressWrapper>
      {hasAccessToPlaceholderBookings &&
        dayDrivingTimes.length > 0 &&
        dayDrivingTimes[0]?.startsOutsideHomeCounty && (
          <Row key="blank-start">
            <CaseButton
              unpadded
              style={{ border: '1px solid', padding: 5 }}
              onClick={handleCreateMorningPlaceholder}
            >
              Skapa utfyllnadsuppdrag
              <FontAwesomeIcon style={{ marginLeft: 'auto' }} icon={faPlus} />
            </CaseButton>
          </Row>
        )}
      <>
        {dayDrivingTimes.map((entry, index) => {
          const key =
            entry.assignment?.assignment.assignmentID ??
            entry.assignmentRequest?.requestID ??
            index;
          return (
            <React.Fragment key={key}>
              {entry.assignment && (
                <AssignmentRow model={entry.assignment} drivingTimes={entry} />
              )}
              {entry.assignmentRequest && (
                <AssignmentRequestRow
                  model={entry.assignmentRequest}
                  drivingTimes={entry}
                />
              )}
            </React.Fragment>
          );
        })}
      </>
      {hasAccessToPlaceholderBookings &&
        dayDrivingTimes.length > 0 &&
        dayDrivingTimes[dayDrivingTimes.length - 1]?.assignment?.case
          ?.caseTypeID !== CaseTypeEnum.Placeholder && (
          <Row key="blank-end">
            <CaseButton
              unpadded
              style={{ border: '1px solid', padding: 5 }}
              onClick={handleCreateAfternoonPlaceholder}
            >
              Skapa utfyllnadsuppdrag
              <FontAwesomeIcon style={{ marginLeft: 'auto' }} icon={faPlus} />
            </CaseButton>
          </Row>
        )}
      {!bookedAssignmentsCall.data?.length && <Row>Inga bokade uppdrag</Row>}
    </Wrapper>
  );
};

export default WorkTimeWidget;
