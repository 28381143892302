/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AssignmentTypeEnum =
  (typeof AssignmentTypeEnum)[keyof typeof AssignmentTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentTypeEnum = {
  Normal: 1,
  WithTest: 2,
  FurtherTransport: 3,
  PlannedFurtherTransport: 4,
  CarPlaceholder: 5,
  GoByTrain: 6,
  GoByBus: 7,
  GoByPlane: 8,
  GoByBoat: 9,
  GoByTaxi: 10,
} as const;
