/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type PredefinedAction =
  (typeof PredefinedAction)[keyof typeof PredefinedAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PredefinedAction = {
  ORDER_TO_KUNGSANGEN: 0,
  PARK_ZAKRA_OFFICE: 100,
  PARK_PADEL: 200,
  PARK_RM_LOGISTICS: 300,
  ORDER_TO_PADEL: 400,
  REQUEST_INTERNAL_TRANSPORT: 500,
  CANCEL_INTERNAL_TRANSPORT_REQUEST: 600,
  REQUEST_OVERRIDE_DRIVING_POLICY: 700,
} as const;
