import useMe from 'contexts/authentication/useMe';
import { useMemo } from 'react';

const useHasTestAccessToPlaceholderCases = () => {
  const me = useMe();

  const hasAdminAccessToPlaceholderCases = useMemo(() => {
    if (!me) {
      return false;
    }

    return !!me.tempTestPlaceholderAssignments;
  }, [me]);

  return hasAdminAccessToPlaceholderCases;
};

export default useHasTestAccessToPlaceholderCases;
