import {
  faArrowLeft,
  faBan,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AssignedTimeDevMenuDto,
  AssignedTimeDto,
  DrivingPolicyReportDto,
} from 'api/model';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  formatDate,
  formatTime,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import Checkbox from './inputs/Checkbox';
import useTranslations from 'contexts/basicData/useTranslations';

const Wrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

const TimeSlotHeader = styled.span`
  white-space: nowrap;
  display: flex;
  gap: 5px;
  color: black;
  background-color: white;
  font-weight: bold;
  width: 100%;
`;

const Duration = styled.div<{
  duration: number;
  durationSinceStart: number;
}>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: ${(props) =>
    (20 * props.durationSinceStart) / 1000 / 60 / 60}px; // the starting point
  width: ${(props) =>
    (20 * props.duration) / 1000 / 60 / 60}px; // the width of the time slot
  height: 32px;
  font-size: 14px;
`;

const TimeSlot = styled(Duration)<{
  isCached: boolean;
  isDevMenu?: boolean;
  isBreakingDrivingPolicy: boolean;
}>`
  height: 32px;
  font-size: 14px;
  background-color: ${({
    theme,
    isCached,
    isDevMenu,
    isBreakingDrivingPolicy,
  }) =>
    isBreakingDrivingPolicy
      ? 'rgba(255, 0, 0, 0.232)'
      : isDevMenu && !isCached
        ? theme.colors.background.tertiary
        : theme.colors.foreground.good};
  margin-bottom: 25px;
`;

const TimeSpan = styled.span`
  white-space: nowrap;
  margin-left: 5px;
`;

const WarningSymbols = styled.div``;

const AssignmentLink = styled.a`
  color: ${({ theme }) => theme.colors.foreground.primary};
  padding: 2px;
  margin-top: 1px;
  width: 100%;
`;
type AssignedTimesForTimeline = AssignedTimeDto | AssignedTimeDevMenuDto;

interface Props<T extends AssignedTimesForTimeline> {
  assignedTimes: T[];
  isDevMenu?: boolean;
}
const AssignedTimesTimeline = <T extends AssignedTimesForTimeline>({
  assignedTimes,
  isDevMenu = false,
}: Props<T>) => {
  const { caseTypes } = useTranslations();
  const [includeNotCached, setIncludeNotCached] = React.useState(true);

  const getStatuses = (report?: DrivingPolicyReportDto) => {
    if (!report) {
      return [];
    }
    const statuses = [];

    if (report.hasConsecutiveHoursOfRestLast24Hours === false) {
      statuses.push(
        'För lite sammanhängande dygnsvila eller för lång arbetsdag'
      );
    }
    if (report.hasConsecutiveHoursOfRestThisWeek === false) {
      statuses.push('För lite vila denna vecka');
    }
    if (report.isWithinAllowedDrivingHoursForAverageWeek === false) {
      statuses.push('Har för många körtimmar');
    }

    return statuses;
  };

  const sortedAssignedTimes = useMemo(() => {
    let result = assignedTimes.sort(
      (a, b) => (a.start?.getTime() ?? 0) - (b.start?.getTime() ?? 0)
    );
    if (!includeNotCached) {
      result = result.filter((time) => {
        if ('isCached' in time) {
          return time.isCached;
        }
        return true;
      });
    }
    return result;
  }, [assignedTimes, includeNotCached]);

  const firstStartTime = useMemo(() => {
    return (
      sortedAssignedTimes.find((time) => time.start)?.start?.getTime() ?? 0
    );
  }, [sortedAssignedTimes]);

  return (
    <Wrapper>
      <h1>Tidslinje</h1>
      {isDevMenu && (
        <Checkbox
          checked={includeNotCached}
          onChange={(e) => setIncludeNotCached(e.currentTarget.checked)}
        >
          Visa även uppdrag som inte är i cachen
        </Checkbox>
      )}
      {sortedAssignedTimes.map((time) => {
        const useStart = time.actualStart ?? time.start;
        const useEnd = time.actualEnd ?? time.end;
        const durationSinceStart = Math.max(
          (useStart?.getTime() ?? 0) - firstStartTime,
          0
        );
        const duration = Math.max(
          (useEnd?.getTime() ?? 0) - (useStart?.getTime() ?? 0),
          0
        );

        const warnings = getStatuses(time.report);

        const showOriginalEstimates =
          time.actualEnd &&
          time.actualStart &&
          time.estimatedEnd &&
          time.estimatedStart;

        const estimatedDuration =
          (time.estimatedEnd?.getTime() ?? 0) -
          (time.estimatedStart?.getTime() ?? 0);

        return (
          <TimeSlot
            isCached={'isCached' in time && time.isCached}
            key={time.assignmentId}
            durationSinceStart={durationSinceStart}
            duration={duration}
            isDevMenu={isDevMenu}
            isBreakingDrivingPolicy={warnings.length > 0}
          >
            <TimeSlotHeader>
              <p>{time.start && formatDate(new Date(time.start))}</p>
              <WarningSymbols>
                {'isCached' in time && !time.isCached ? (
                  <FontAwesomeIcon
                    icon={faBan}
                    color="gray"
                    title="Not cached"
                  />
                ) : null}
                {warnings.length > 0
                  ? warnings.map((warning) => (
                      <FontAwesomeIcon
                        icon={faWarning}
                        color="red"
                        title={warning}
                      />
                    ))
                  : null}
              </WarningSymbols>
            </TimeSlotHeader>
            <TimeSpan>
              {useStart && formatTime(useStart)}
              {' - '}
              {useEnd && formatTime(useEnd)} (
              {getHoursAndMinutesFromMillisecondsString(duration)})
            </TimeSpan>
            {showOriginalEstimates && (
              <div
                style={{ display: 'flex', gap: '5px', whiteSpace: 'nowrap' }}
              >
                <TimeSpan>
                  {time.estimatedStart &&
                    formatTime(new Date(time.estimatedStart))}
                  {' - '}
                  {time.estimatedEnd &&
                    formatTime(new Date(time.estimatedEnd))}{' '}
                  ({getHoursAndMinutesFromMillisecondsString(estimatedDuration)}
                  )
                </TimeSpan>
                <span>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Estimat
                </span>
              </div>
            )}
            <AssignmentLink
              href={`/sok/${time.caseId}`}
              rel="noreferrer"
              target="_blank"
              style={{ whiteSpace: 'nowrap' }}
            >
              {time.registrationNumber} {caseTypes[time.caseTypeId]} (
              {time.assignmentId})
            </AssignmentLink>
          </TimeSlot>
        );
      })}
    </Wrapper>
  );
};

export default AssignedTimesTimeline;
