import { CaseStatusEnum, CaseTypeEnum } from 'api';
import Input, { CombineInputs } from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import KeyValueList, { KeyListRow } from 'components/KeyValueList';
import useTranslations from 'contexts/basicData/useTranslations';
import {
  CaseDataField,
  caseDataFormToCaseModel,
} from '../DetailedCaseFormProvider/useCaseDataForm';
import React, { FC, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useDetailedCaseDataForm } from '../DetailedCaseFormProvider';
import FormValidations from '../FormValidations';
import CompanyPicker from 'components/CompanyPicker';
import { arraySpreadIf } from 'utils/spreading';
import Checkbox from 'components/inputs/Checkbox';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import { TyreTypeEnum } from 'api/model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import assignmentWarnings from 'utils/assignment-warnings';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import useHasTestAccessToPlaceholderCases from 'hooks/useHasTestAccessToPlaceholderCases';

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
`;

const BigLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 50px;
  font-weight: bold;
  ${({ theme }) => css`
    color: ${theme.colors.foreground.tertiary};
  `}
  // only upper-case
  text-transform: uppercase;
`;

const Warning = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.background.negative};
  `}
`;
interface Props {
  className?: string;
}

const CaseDataForm: FC<Props> = ({ className }) => {
  const hasAccessToPlaceholderBookings = useHasTestAccessToPlaceholderCases();

  const { input, select, onChange, state, validation } =
    useDetailedCaseDataForm();
  const { caseTypes, caseStatuses, tyreTypes } = useTranslations();

  const selectedCaseStatus = Number(state[CaseDataField.caseStatusID]);
  const selectableCaseStatuses: CaseStatusEnum[] = Object.values(
    CaseStatusEnum
  ).filter(
    (v) =>
      typeof v === 'number' &&
      (v === selectedCaseStatus || v !== CaseStatusEnum.Pending)
  ) as number[];

  const currentCaseWarnings = useMemo(() => {
    const caseModel = caseDataFormToCaseModel(undefined, state, []);

    const warnings = assignmentWarnings.getCaseWarnings(caseModel);

    return warnings;
  }, [state]);

  const me = useMe();
  const selectableCaseTypes: CaseTypeEnum[] = useMemo(() => {
    const allowedCaseTypesForAnnonsAdmin = [
      CaseTypeEnum.InternalDelivery,
      // CaseTypeEnum.Extra, // Exclude Extra as it is being deprecated in favor of new case types // Jonas Frid 2024-04-18
      CaseTypeEnum.ExtraCostExternalWorkshopOut,
      CaseTypeEnum.ExtraCostExternalWorkshopReturn,
      CaseTypeEnum.FacilityToFacility,
      CaseTypeEnum.RMOther,
      CaseTypeEnum.Swamp,
    ];

    if (me?.roles.includes(Roles.Annons) && !me?.roles.includes(Roles.Admin)) {
      return Object.values(CaseTypeEnum).filter(
        (v) =>
          typeof v === 'number' && allowedCaseTypesForAnnonsAdmin.includes(v)
      ) as number[];
    }

    return Object.values(CaseTypeEnum).filter(
      (v) =>
        typeof v === 'number' &&
        v !== CaseTypeEnum.Extra && // Exclude Extra as it is being deprecated in favor of new case types // Jonas Frid 2024-04-18
        (v !== CaseTypeEnum.Placeholder || hasAccessToPlaceholderBookings) // Exclude Placeholder if user does not have access to it while we are testing the feature in production // Jonas Frid 2024-11-26
    ) as number[];
  }, [hasAccessToPlaceholderBookings, me?.roles]);

  const selectableTyreTypes: TyreTypeEnum[] = useMemo(() => {
    return Object.values(TyreTypeEnum).filter(
      (v) => typeof v === 'number'
    ) as TyreTypeEnum[];
  }, []);

  const currentCaseType = Number(state[CaseDataField.caseTypeID]);
  useEffect(() => {
    if (currentCaseType === CaseTypeEnum.InternalDelivery) {
      onChange(CaseDataField.customerName, '-');
    } else if (state[CaseDataField.customerName] === '-') {
      onChange(CaseDataField.customerName, '');
    }

    if (
      [CaseTypeEnum.HomeDelivery, CaseTypeEnum.Purchase].includes(
        currentCaseType
      ) &&
      state[CaseDataField.sendCustomerSurvey] !== 'false'
    ) {
      onChange(CaseDataField.sendCustomerSurvey, 'true');
    } else {
      onChange(CaseDataField.sendCustomerSurvey, 'false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCaseType]);

  const mkInput = (
    name: CaseDataField,
    label: string,
    type?: React.HTMLInputTypeAttribute
  ): KeyListRow => ({
    key: label,
    value: (
      <Input onChange={input(name)} small type={type} value={state[name]} />
    ),
  });

  return (
    <Wrapper className={className}>
      <Row>
        {currentCaseType === CaseTypeEnum.Placeholder ? (
          <BigLabel>
            <FontAwesomeIcon icon={faWarning} />
            {caseTypes[currentCaseType]}
            <FontAwesomeIcon icon={faWarning} />
          </BigLabel>
        ) : (
          <>
            <KeyValueList
              colonKey
              keyWidth="90px"
              rows={[
                {
                  key: 'Typ',
                  value: (
                    <Select
                      onChange={select(CaseDataField.caseTypeID)}
                      small
                      value={state[CaseDataField.caseTypeID]}
                    >
                      {selectableCaseTypes.map((type) => (
                        <option key={type} value={type}>
                          {caseTypes[type]}
                        </option>
                      ))}
                    </Select>
                  ),
                },
                {
                  key: 'Status',
                  value: (
                    <Select
                      onChange={select(CaseDataField.caseStatusID)}
                      small
                      value={state[CaseDataField.caseStatusID]}
                    >
                      {selectableCaseStatuses.map((type) => (
                        <option key={type} value={type}>
                          {caseStatuses[type]}
                        </option>
                      ))}
                    </Select>
                  ),
                },
                ...arraySpreadIf(
                  Number(state[CaseDataField.caseStatusID]) ===
                    CaseStatusEnum.Closed || !!state[CaseDataField.closedDate],
                  mkInput(
                    CaseDataField.closedDate,
                    'Stängdes',
                    'datetime-local'
                  )
                ),
                {
                  key: 'Företag',
                  value: (
                    <CompanyPicker
                      onCompanySelected={(company) => {
                        onChange(
                          CaseDataField.companyID,
                          company ? String(company.companyID) : ''
                        );
                      }}
                      filter={(company) => {
                        if (
                          state[CaseDataField.caseTypeID] ===
                          String(CaseTypeEnum.InternalDelivery)
                        ) {
                          return (
                            company.showInInternalDeliveryOverview === true ||
                            company.isLogisticsCenter === true
                          );
                        }
                        return true;
                      }}
                      selectedCompanyId={
                        state[CaseDataField.companyID] === ''
                          ? null
                          : Number(state[CaseDataField.companyID])
                      }
                    />
                  ),
                },
                mkInput(CaseDataField.bookerName, 'Bokare'),
                mkInput(CaseDataField.bookerPhoneNumber, 'Bokare tel'),
              ]}
            />

            <KeyValueList
              colonKey
              keyWidth="90px"
              rows={[
                mkInput(CaseDataField.customerName, 'Kund'),
                mkInput(CaseDataField.customerPhoneNumber, 'Kund tel'),
                {
                  key: 'Regnr',
                  value: (
                    <CombineInputs>
                      <Input
                        onChange={input(CaseDataField.registrationNumber)}
                        small
                        value={state[CaseDataField.registrationNumber]}
                        width={90}
                      />
                      <Select
                        onChange={select(CaseDataField.vehicleGearBox)}
                        small
                        value={state[CaseDataField.vehicleGearBox]}
                        width={90}
                      >
                        {/* <option value="">Okänt</option> */}
                        <option value="true">Manuell</option>
                        <option value="false">Automat</option>
                      </Select>
                    </CombineInputs>
                  ),
                },
                mkInput(CaseDataField.vehicleMileage, 'Miltal', 'number'),
                mkInput(CaseDataField.vehicleYearModel, 'Årsmodell', 'number'),
                mkInput(CaseDataField.rideOrderID, 'RideOrderID'),
              ]}
            />
            <KeyValueList
              colonKey
              keyWidth="90px"
              rows={[
                {
                  key: 'BE-körkort (släp)',
                  value: (
                    <Checkbox
                      checked={state[CaseDataField.isTrailer] === 'true'}
                      onChange={(eve) =>
                        onChange(
                          CaseDataField.isTrailer,
                          eve.currentTarget.checked ? 'true' : 'false'
                        )
                      }
                    />
                  ),
                },
                {
                  key: 'C-körkort (lastbil)',
                  value: (
                    <Checkbox
                      checked={state[CaseDataField.isTruck] === 'true'}
                      onChange={(eve) =>
                        onChange(
                          CaseDataField.isTruck,
                          eve.currentTarget.checked ? 'true' : 'false'
                        )
                      }
                    />
                  ),
                },
                {
                  key: 'Premiumbil',
                  value: (
                    <Checkbox
                      checked={state[CaseDataField.isPremiumCar] === 'true'}
                      onChange={(eve) =>
                        onChange(
                          CaseDataField.isPremiumCar,
                          eve.currentTarget.checked ? 'true' : 'false'
                        )
                      }
                    />
                  ),
                },
                {
                  key: 'Elbil',
                  value: (
                    <Checkbox
                      checked={state[CaseDataField.isElectric] === 'true'}
                      onChange={(eve) =>
                        onChange(
                          CaseDataField.isElectric,
                          eve.currentTarget.checked ? 'true' : 'false'
                        )
                      }
                    />
                  ),
                },
                ...arraySpreadIf(
                  Number(state[CaseDataField.caseTypeID]) ===
                    CaseTypeEnum.InternalDelivery ||
                    Number(state[CaseDataField.caseTypeID]) ===
                      CaseTypeEnum.Extra,
                  {
                    key: 'Däcktyp',
                    value: (
                      <Select
                        onChange={select(CaseDataField.vehicleTyreType)}
                        small
                        value={state[CaseDataField.vehicleTyreType]}
                      >
                        <option value={undefined}>Välj däcktyp</option>
                        {selectableTyreTypes.map((type) => (
                          <option key={type} value={type}>
                            {tyreTypes[type]}
                          </option>
                        ))}
                      </Select>
                    ),
                  }
                ),
                {
                  key: 'Skicka kundundersökning',
                  value: (
                    <span>
                      <Checkbox
                        disabled={
                          ![
                            CaseTypeEnum.HomeDelivery,
                            CaseTypeEnum.Purchase,
                          ].includes(currentCaseType)
                        }
                        checked={
                          state[CaseDataField.sendCustomerSurvey] === 'true'
                        }
                        onChange={(eve) =>
                          onChange(
                            CaseDataField.sendCustomerSurvey,
                            eve.currentTarget.checked ? 'true' : 'false'
                          )
                        }
                      />
                      {![
                        CaseTypeEnum.HomeDelivery,
                        CaseTypeEnum.Purchase,
                      ].includes(currentCaseType) && (
                        <span
                          style={{
                            marginLeft: 5,
                          }}
                        >
                          (endast för inköp och hemleverans)
                        </span>
                      )}
                    </span>
                  ),
                },
              ]}
            />
          </>
        )}
      </Row>

      <FormValidations validations={validation} />
      {currentCaseWarnings.map((warning) => (
        <Warning key={warning.warning}>
          {warning.icon ?? <FontAwesomeIcon icon={faWarning} />}{' '}
          {warning.warning}
        </Warning>
      ))}
    </Wrapper>
  );
};

export default CaseDataForm;
