import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import useUsers from 'contexts/basicData/useUsers';
import { formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Checkbox from 'components/inputs/Checkbox';
import Routes from 'constants/Routes';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { useCaseMarkEventsAsReadByAdmin } from 'api/case/case';
import { useQueryClient } from '@tanstack/react-query';
import { CaseEventDTO } from 'api/model';
import { GoSupportWidgetsEnum, LongTitles, UPDATE_INTERVAL } from '../utils';
import {
  Centered,
  getTableRowClassName,
  SavingRowAnimationWrapper,
} from '../table-helpers';
import { ITranslationLookups } from 'api';
import useTranslations from 'contexts/basicData/useTranslations';
import { makeStringComparator } from 'utils/sorting';
import {
  getGoSupportGetUnhandledNoTyresLoadedEventsQueryKey,
  useGoSupportGetUnhandledNoTyresLoadedEvents,
} from 'api/go-support/go-support';

const TABLE_NAME = 'work-gear-reports';

const NotLoadedTyresWidget = () => {
  const [data, setData] = useState<CaseEventDTO[]>([]);
  const [ongoingSaves, setOngoingSaves] = useState<number[]>([]);
  const users = useUsers();
  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();
  const translations = useTranslations();

  const queryClient = useQueryClient();
  const { mutateAsync: markEventAsRead, status: markAsReadStatus } =
    useCaseMarkEventsAsReadByAdmin({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            getGoSupportGetUnhandledNoTyresLoadedEventsQueryKey(),
          ]);
        },
      },
    });

  const getNotLoadedTyres = useGoSupportGetUnhandledNoTyresLoadedEvents(
    {
      startDate: daterange[0] ?? undefined,
      endDate: daterange[1] ?? undefined,
      businessUnitIDs: selectedBusinessUnits,
    },
    {
      query: {
        refetchInterval: UPDATE_INTERVAL,
      },
    }
  );

  useEffect(() => {
    if (getNotLoadedTyres.data) {
      setData(getNotLoadedTyres.data);
    } else {
      setData([] as CaseEventDTO[]);
    }
  }, [getNotLoadedTyres.data]);

  const filteredGearReports = useMemo(() => {
    let filtered = [...data];

    if (searchText.length > 0) {
      filtered = filtered.length
        ? filtered
            .filter((c) => {
              return (
                c.comment?.toLowerCase().includes(searchText.toLowerCase()) ||
                c.registrationNumber
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                (users[c.createdByID]?.name ?? '')
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            })
            .sort((a, b) => b.created.getTime() - a.created.getTime()) // Latest first
        : [];
    }

    return filtered;
  }, [data, searchText, users]);

  const columnSettings: ColumnSetting<CaseEventDTO, ITranslationLookups>[] =
    useMemo(() => {
      const columns: ColumnSetting<CaseEventDTO, ITranslationLookups>[] = [
        {
          head: 'Nr',
          render: (e, t, f, i) => i + 1,
          width: 15,
        },
        {
          head: 'Reg.nr',
          render: (row) => `${row.registrationNumber}`,
          width: 60,
          sortFunction: (a, b) => {
            return a.registrationNumber.localeCompare(b.registrationNumber);
          },
        },
        {
          head: 'Status',
          render: (row) =>
            `${row.assignmentStatus ? translations.assignmentStatuses[row.assignmentStatus] : ''}`,
          width: 50,
          sortFunction: (a, b) => {
            return a.assignmentStatus - b.assignmentStatus;
          },
        },
        {
          head: 'Tid',
          render: (row) => `${formatDateTime(row.created)}`,
          width: 100,
          sortFunction: (a, b) => a.created.getTime() - b.created.getTime(),
        },
        {
          head: 'Användare',
          render: (row) => {
            return (
              <span>
                {users[row.createdByID] ? users[row.createdByID].name : ''}
              </span>
            );
          },
          width: 100,
          sortFunction: (a, b) =>
            (users[a.createdByID]?.name ?? '').localeCompare(
              users[b.createdByID]?.name ?? ''
            ),
        },
        {
          head: 'Telefonnummer',
          render: (row) => {
            return (
              <span>
                {users[row.createdByID]
                  ? users[row.createdByID].phoneNumber
                  : ''}
              </span>
            );
          },
          width: 120,
          sortFunction: (a, b) =>
            (users[a.createdByID]?.phoneNumber ?? '').localeCompare(
              users[b.createdByID]?.phoneNumber ?? ''
            ),
        },
        {
          head: 'Rapport',
          render: (row) => row.comment,
          width: 300,
          sortFunction: makeStringComparator((row) => row.data ?? row.comment),
        },
        {
          head: ' ',
          render: (row) => (
            <Centered>
              <FontAwesomeIcon
                onClick={() => {
                  window.open(
                    `${Routes.search.index}/${row.caseID}`,
                    row.caseID.toString() ?? '_blank'
                  );
                }}
                icon={faArrowUpRightFromSquare}
                fontSize="18px"
                style={{ cursor: 'pointer' }}
              />
            </Centered>
          ),
          width: 20,
        },
        {
          head: 'Klart',
          render: (row) => (
            <Centered>
              <Checkbox
                disabled={markAsReadStatus === 'loading'}
                onChange={async () => {
                  setOngoingSaves([...ongoingSaves, row.id]);
                  await markEventAsRead({ data: [row.id] });
                  setOngoingSaves(ongoingSaves.filter((id) => id !== row.id));
                }}
              />
            </Centered>
          ),
          width: 30,
        },
      ];
      return columns;
    }, [
      markAsReadStatus,
      markEventAsRead,
      ongoingSaves,
      translations.assignmentStatuses,
      users,
    ]);
  return (
    <Widget
      title={LongTitles[GoSupportWidgetsEnum.NotLoadedTyres]}
      count={data.length}
      isLoading={getNotLoadedTyres.isLoading}
      info="Markera som klar för att markera utrustningsrapport som läst."
    >
      <SavingRowAnimationWrapper
        tableName={TABLE_NAME}
        savingIds={ongoingSaves}
      >
        <Table
          columnSettings={columnSettings}
          rows={filteredGearReports}
          rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.id)}
          useColumnWidthAsFlexAmount
          renderProps={translations}
        />
      </SavingRowAnimationWrapper>
    </Widget>
  );
};

export default NotLoadedTyresWidget;
