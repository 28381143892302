import {
  faArrowUp,
  faCarAlt,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssignmentGetUnassignedAssignmentsByAddress } from 'api/assignment/assignment';
import {
  AssignmentModel,
  AssignmentTypeEnum,
  AssignmentViewModel,
  CaseModel,
  DrivingPolicyReportDto,
} from 'api/model';
import { useSetupGetBasicData } from 'api/setup/setup';
import TextButton from 'components/inputs/TextButton';
import Modal from 'components/Modal';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import useCompanies from 'contexts/basicData/useCompanies';
import useTranslations from 'contexts/basicData/useTranslations';
import useModalStack from 'contexts/modal/useModalStack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { openGoogleMapsDirections } from 'utils/address-helper';
import {
  formatDateTime,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import { makeNumberComparator, makeStringComparator } from 'utils/sorting';
import { useUserGetDrivingPolicyReportForUnPlannedAssignmentHook } from 'api/user/user';
import { formatAddress } from 'utils/address';
import ReplacePlaceholderAssignmentActionDropdown from './ReplacePlaceholderAssignmentActionDropdown';

const ModalTextButton = styled(TextButton)`
  text-align: left;
  padding: 0px;
`;

const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const Span = styled.span`
  white-space: wrap;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InvalidRowClassName = 'invalid-row';

const TableStylerWrapper = styled.div`
  display: contents;

  .${InvalidRowClassName} {
    color: ${({ theme }) => theme.colors.foreground.error};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type AssignmentViewModelWithDrivingPolicyAndEstimatedEndTime = {
  drivingPolicy?: DrivingPolicyReportDto;
  assignment: AssignmentModel;
  case: CaseModel;
  estimatedEndTime?: Date;
};

const getAssignmentPriority = (assignment: AssignmentViewModel) => {
  // assignmenttype  Normal or WithTest is Prio 1
  // assignmenttype FurtherTransport is Prio 2

  if (
    (
      [
        AssignmentTypeEnum.Normal,
        AssignmentTypeEnum.WithTest,
      ] as AssignmentTypeEnum[]
    ).includes(assignment.assignment.assignmentTypeID)
  ) {
    return 1;
  }

  if (
    assignment.assignment.assignmentTypeID ===
    AssignmentTypeEnum.FurtherTransport
  ) {
    return 2;
  }

  return 3;
};

const getAssignmentPriorityIcon = (prio: number): React.ReactNode => {
  switch (prio) {
    case 1:
      return (
        <IconWrapper>
          <FontAwesomeIcon color="red" icon={faArrowUp} />
        </IconWrapper>
      );

    default:
      return <Center>-</Center>;
  }
};

type ReplacePlaceholderCarButtonProps = {
  assignment: AssignmentViewModel;
  drivingPolicyReport: DrivingPolicyReportDto | undefined;
  // onConfirm: () => void;
};

type ReplacePlaceholderCarModalProps = ReplacePlaceholderCarButtonProps & {
  onCancel: () => void;
};

const ReplacePlaceholderCarModal: React.FC<ReplacePlaceholderCarModalProps> = ({
  assignment,
  drivingPolicyReport,
  // onConfirm,
  onCancel,
}) => {
  const { companiesByAddrDict } = useCompanies();
  const basicdata = useSetupGetBasicData();
  const consecutiveHoursOfRestLast24Hours =
    basicdata.data?.consecutiveHoursOfRestLast24Hours ?? 0;
  const workHoursToday = drivingPolicyReport?.workHoursToday ?? 0;
  const { caseTypes, assignmentTypes } = useTranslations();
  const getUnassignedAssignmentsByAddressCall =
    useAssignmentGetUnassignedAssignmentsByAddress();

  const refresh = async () => {
    await getUnassignedAssignmentsByAddressCall.mutateAsync({
      data: {
        approximateStartTime: new Date(),
        fromAddress: assignment.assignment.fromAddress,
        fromZip: assignment.assignment.fromZip,
        toAddress: assignment.assignment.toAddress,
        toZip: assignment.assignment.toZip,
      },
    });
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [assignmentsWithDrivingPolicy, setAssignmentsWithDrivingPolicy] =
    useState<AssignmentViewModelWithDrivingPolicyAndEstimatedEndTime[]>([]);

  const getDrivingPolicyForUplannedAssignmentCall =
    useUserGetDrivingPolicyReportForUnPlannedAssignmentHook();

  useEffect(() => {
    const fetchDrivingPolicy = async () => {
      // for each assignment, get the driving policy async
      const result = await Promise.all(
        getUnassignedAssignmentsByAddressCall.data?.map(async (a) => {
          if (
            !a.assignment.estimatedDuration ||
            !a.assignment.bookedTo ||
            !a.assignment.estimatedStartTime
          ) {
            return {
              ...a,
            };
          }

          // as these are stopovers on their way home, we can base the times on the end time of our current assignment
          const usedStartTime = new Date(
            assignment.assignment.estimatedStartTime!.getTime() +
              assignment.assignment.estimatedDuration!
          );
          const estimatedDuration = a.assignment.estimatedDuration!;
          const usedEndTime = new Date(
            usedStartTime.getTime() + estimatedDuration
          );
          const drivingPolicy = await getDrivingPolicyForUplannedAssignmentCall(
            {
              userId: assignment.assignment.assignedTo!.userID!,
              bookedTo: assignment.assignment.bookedTo,
              startOrEstimatedStartTime: usedStartTime,
              endOrEstimatedEndTime: usedEndTime,
              estimatedDuration,
            }
          );

          return {
            ...a,
            drivingPolicy,
            estimatedEndTime: usedEndTime,
          };
        }) ?? []
      );
      setAssignmentsWithDrivingPolicy(result);
    };

    if (getUnassignedAssignmentsByAddressCall.data) {
      fetchDrivingPolicy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUnassignedAssignmentsByAddressCall.data]);

  const goToCase = useCallback((caseID: number) => {
    // open the case in a new tab with the caseID
    window.open(`/sok/${caseID}`, caseID.toString());
  }, []);

  const getWorkTimeIncludingNewAssignment = useCallback(
    (_assignment: AssignmentModel) => {
      // get the current estimated duration in hours (default to 0)
      if (!_assignment.estimatedDuration) {
        return undefined;
      }
      const estimatedDuration = _assignment.estimatedDuration!;
      const alreadyUsedTime = workHoursToday * 60 * 60 * 1000;

      return estimatedDuration + alreadyUsedTime;
    },
    [workHoursToday]
  );

  const getTimeLeftIncludingNewAssignment = useCallback(
    (_assignment: AssignmentModel) => {
      // get the current estimated duration in hours (default to 0)
      if (!_assignment.estimatedDuration) {
        return undefined;
      }
      const estimatedDuration = _assignment.estimatedDuration!;
      const totalTimeAllowedToday =
        (24 - consecutiveHoursOfRestLast24Hours) * 60 * 60 * 1000;
      const alreadyUsedTime = workHoursToday * 60 * 60 * 1000;

      const timeLeftOfDay =
        totalTimeAllowedToday - estimatedDuration - alreadyUsedTime;

      return timeLeftOfDay;
    },
    [consecutiveHoursOfRestLast24Hours, workHoursToday]
  );

  const columnSettings = useMemo((): ColumnSetting<
    AssignmentViewModelWithDrivingPolicyAndEstimatedEndTime,
    any
  >[] => {
    return [
      {
        head: 'Prio',
        render: (a) => getAssignmentPriorityIcon(getAssignmentPriority(a)),
        width: 50,
        sortFunction: makeNumberComparator((a) => getAssignmentPriority(a)),
      },
      {
        head: 'Regnr',
        render: (a) => a.case.registrationNumber,
        width: 100,
        sortFunction: makeStringComparator((a) => a.case.registrationNumber),
      },
      {
        head: 'Est. tid',
        render: (a) =>
          a.assignment.estimatedDuration
            ? getHoursAndMinutesFromMillisecondsString(
                a.assignment.estimatedDuration
              )
            : '–',
        width: 100,
        sortFunction: makeNumberComparator(
          (a) => a.assignment.estimatedDuration ?? 0
        ),
      },
      {
        head: 'Est. starttid',
        render: (a) =>
          a.assignment.estimatedStartTime
            ? formatDateTime(a.assignment.estimatedStartTime)
            : '–',
        width: 150,
        sortFunction: makeNumberComparator(
          (a) => a.assignment.estimatedStartTime?.getTime() ?? 0
        ),
      },
      {
        head: 'Est. sluttid',
        render: (a) =>
          a.estimatedEndTime ? formatDateTime(a.estimatedEndTime) : '–',
        width: 150,
        sortFunction: makeNumberComparator(
          (a) => a.estimatedEndTime?.getTime() ?? 0
        ),
      },
      {
        head: 'Bokad till',
        render: (a) =>
          a.assignment.bookedTo ? formatDateTime(a.assignment.bookedTo) : '–',
        width: 150,
        sortFunction: makeNumberComparator(
          (a) => a.assignment.bookedTo?.getTime() ?? 0
        ),
      },
      {
        head: 'Total tid inkl uppdraget',
        render: (a) => {
          const workTime = getWorkTimeIncludingNewAssignment(a.assignment);
          return workTime
            ? getHoursAndMinutesFromMillisecondsString(workTime)
            : '–';
        },
        width: 200,
        sortFunction: makeNumberComparator(
          (a) => getTimeLeftIncludingNewAssignment(a.assignment) ?? 0
        ),
      },
      {
        head: 'Tid kvar inkl uppdraget',
        render: (a) => {
          const timeLeft = getTimeLeftIncludingNewAssignment(a.assignment);
          return timeLeft
            ? getHoursAndMinutesFromMillisecondsString(timeLeft)
            : '-';
        },
        width: 200,
        sortFunction: makeNumberComparator(
          (a) => getTimeLeftIncludingNewAssignment(a.assignment) ?? 0
        ),
      },
      {
        head: 'Ärendetyp',
        render: (a) => caseTypes[a.case.caseTypeID],
        width: 100,
        sortFunction: makeStringComparator(
          (a) => caseTypes[a.case.caseTypeID] ?? ''
        ),
      },
      {
        head: 'Uppdragstyp',
        render: (a) => assignmentTypes[a.assignment.assignmentTypeID],
        width: 120,
        sortFunction: makeStringComparator(
          (a) => assignmentTypes[a.assignment.assignmentTypeID] ?? ''
        ),
      },
      {
        head: 'Från',
        render: (a) => {
          const addressKey =
            `${a.assignment.fromAddress}${a.assignment.fromZip}${a.assignment.fromCity}`
              .toLowerCase()
              .replaceAll(' ', '');
          const addressString =
            companiesByAddrDict.get(addressKey)?.name ??
            `${a.assignment.fromAddress}, ${a.assignment.fromZip}, ${a.assignment.fromCity}`;
          return <Span>{addressString}</Span>;
        },
        width: 200,
        sortFunction: makeStringComparator((a) => a.assignment.fromAddress),
      },
      {
        head: 'Till',
        render: (a) => {
          const addressKey =
            `${a.assignment.toAddress}${a.assignment.toZip}${a.assignment.toCity}`
              .toLowerCase()
              .replaceAll(' ', '');
          const addressString =
            companiesByAddrDict.get(addressKey)?.name ??
            `${a.assignment.toAddress}, ${a.assignment.toZip}, ${a.assignment.toCity}`;
          return <Span>{addressString}</Span>;
        },
        width: 200,
        sortFunction: makeStringComparator((a) => a.assignment.toAddress),
      },
      {
        head: '',
        render: (a) => (
          <TextButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              openGoogleMapsDirections({
                fromAddress: assignment.assignment.toAddress,
                fromCity: assignment.assignment.toCity,
                fromZip: assignment.assignment.toZip,
                toAddress: a.assignment.fromAddress,
                toCity: a.assignment.fromCity,
                toZip: a.assignment.fromZip,
              });
            }}
          >
            <img
              alt="Google maps icon"
              height={15}
              src="/images/googlemapsiconsmall.png"
              width={15}
            />
          </TextButton>
        ),
        width: 40,
      },
      {
        head: '',
        render: (a) => (
          <TextButton onClick={() => goToCase(a.case.caseID)}>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </TextButton>
        ),
        width: 40,
      },
      {
        head: '',
        render: (a) => (
          <ReplacePlaceholderAssignmentActionDropdown
            currentAssignment={{
              assignment: assignment.assignment,
              case: assignment.case,
            }}
            selectedAssignment={{
              assignment: a.assignment,
              case: a.case,
            }}
            onUpdated={() => {
              // refresh the list
            }}
          />
        ),
        width: 40,
      },
    ];
  }, [
    assignment.assignment,
    assignment.case,
    assignmentTypes,
    caseTypes,
    companiesByAddrDict,
    getTimeLeftIncludingNewAssignment,
    getWorkTimeIncludingNewAssignment,
    goToCase,
  ]);

  return (
    <Modal
      buttons={[
        {
          label: 'Stäng',
          onClick: onCancel,
        },
      ]}
      closeOnOutsideClick={false}
      onClose={onCancel}
      title="Välj bil för utfyllandsuppdraget"
    >
      <ContentWrapper>
        <Header>
          Från:{' '}
          {formatAddress({
            name: assignment.assignment.fromName,
            address: assignment.assignment.fromAddress,
            zip: assignment.assignment.fromZip,
            city: assignment.assignment.fromCity,
            county: assignment.assignment.fromCounty,
          })}
        </Header>
        <Header>
          Till:{' '}
          {formatAddress({
            name: assignment.assignment.toName,
            address: assignment.assignment.toAddress,
            zip: assignment.assignment.toZip,
            city: assignment.assignment.toCity,
            county: assignment.assignment.toCounty,
          })}
        </Header>
        {getUnassignedAssignmentsByAddressCall.isLoading ? (
          <LoadingSpinner />
        ) : assignmentsWithDrivingPolicy?.length === 0 ? (
          <span>Inga uppdrag hittades</span>
        ) : (
          <TableStylerWrapper>
            <Table
              rows={assignmentsWithDrivingPolicy}
              // onRowClick={(row) => goToCase(row.case.caseID)}
              columnSettings={columnSettings}
              rowClassName={(row) => {
                let rowClassName = '';
                if (row.assignment.estimatedDuration === null) {
                  rowClassName = InvalidRowClassName;
                }
                const { estimatedDuration, estimatedStartTime } =
                  assignment.assignment;
                const assignmentEstimatedEndtime =
                  estimatedStartTime && estimatedDuration
                    ? new Date(estimatedStartTime.getTime() + estimatedDuration)
                    : null;
                const endsTooLate =
                  row.estimatedEndTime &&
                  assignmentEstimatedEndtime &&
                  row.estimatedEndTime.getTime() -
                    assignmentEstimatedEndtime.getTime() >
                    0;
                if (endsTooLate) {
                  rowClassName = InvalidRowClassName;
                }
                return `${rowClassName} ${row.assignment.assignmentID}`;
              }}
              initialSortState={{
                ascending: true,
                sortedColumnIndex: 0,
              }}
            />
          </TableStylerWrapper>
        )}
      </ContentWrapper>
    </Modal>
  );
};

const ReplacePlaceholderCarButton: React.FC<
  ReplacePlaceholderCarButtonProps
> = ({ assignment, drivingPolicyReport }) => {
  const modalStack = useModalStack();

  return (
    <ModalTextButton
      onClick={() => {
        modalStack.push(
          <ReplacePlaceholderCarModal
            assignment={assignment}
            drivingPolicyReport={drivingPolicyReport}
            onCancel={() => modalStack.pop()}
          />
        );
      }}
    >
      Välj bil <FontAwesomeIcon icon={faCarAlt} flip="horizontal" />
    </ModalTextButton>
  );
};

export default ReplacePlaceholderCarButton;
