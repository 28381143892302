import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useAssignmentGetPlannedAssignmentsToSendToFieldTestersCounts,
  useAssignmentSendPlannedAssignmentsToFieldTesters,
} from 'api/assignment/assignment';
import Modal from 'components/Modal';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import { FC } from 'react';
import styled from 'styled-components';
import ToggleableBareAssignmentsList from './TogglableBareAssignmentsList';

// const MessageWrapper = styled.div`
//   margin-bottom: 1rem;
//   max-width: 500px;
//   p {
//     margin-bottom: 0.5rem;
//   }
// `;

const Wrapper = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
  max-width: 500px;
  p {
    margin-bottom: 0.5rem;
  }
`;

interface Props {
  onAssignmentsSent(): void;
  onCancel(): void;
}

const SendCasesToFieldTestersModal: FC<Props> = ({
  onAssignmentsSent,
  onCancel,
}) => {
  const {
    state: { date },
  } = useAssignmentsFilters();
  const {
    mutateAsync: sendPlannedAssignmentsToFieldTestersCall,
    status: sendPlannedAssignmentsToFieldTestersStatus,
  } = useAssignmentSendPlannedAssignmentsToFieldTesters();
  // const sendPlannedAssignmentsToFieldTestersCall = useApiCall(
  //   AssignmentClient,
  //   (client, whichDate: Date) =>
  //     client.sendPlannedAssignmentsToFieldTesters(whichDate)
  // );

  const { data, status: totalAssignmentsToSendStatus } =
    useAssignmentGetPlannedAssignmentsToSendToFieldTestersCounts(
      {
        date,
      },
      {
        query: {
          enabled: !!date,
          staleTime: 0,
        },
      }
    );

  const totalAssignmentsToSend = data?.validAssignmentsCount ?? 0;
  const assignmentsInNeedOfApprovalCount = data?.invalidAssignmentsCount ?? 0;
  const placeholdersWithMissingExpensesCount =
    data?.placeholderAssignmentsWithMissingExpensesCount ?? 0;

  // const totalAssignmentsToSend = useResponse(
  //   AssignmentClient,
  //   (c) => c.getPlannedAssignmentsToSendToFieldTesters(date),
  //   [date]
  // );

  const handleSendToFieldTesters = async () => {
    // if (!totalAssignmentsToSend) return;

    await sendPlannedAssignmentsToFieldTestersCall({
      params: {
        date,
      },
    });
    onAssignmentsSent();
  };

  const getContent = () => {
    if (sendPlannedAssignmentsToFieldTestersStatus === 'loading') {
      return <LoadingSpinner>Skickar ut uppdrag...</LoadingSpinner>;
    }

    switch (totalAssignmentsToSendStatus) {
      case 'loading':
        return <LoadingSpinner />;

      case 'success':
        // if (!totalAssignmentsToSend) {
        //   return (
        //     <MessageWrapper>
        //       <p>Det finns inga uppdrag att skicka ut.</p>
        //       <p>
        //         Förväntade du dig att något annat? Säkerställ att alla planerade
        //         uppdrag har Fälttestare och estimerad tid satt. Om de har det
        //         och ändå inte dyker upp här, hör av dig till support.
        //       </p>
        //     </MessageWrapper>
        //   );
        // }
        return (
          <Wrapper>
            <p>
              <b>{totalAssignmentsToSend}</b> planerade uppdrag kommer att
              skickas till fälttestarna.
              <br />
              <ToggleableBareAssignmentsList
                assignments={data?.validAssignments ?? []}
              />
            </p>

            {assignmentsInNeedOfApprovalCount > 0 && (
              <div>
                <b>{assignmentsInNeedOfApprovalCount}</b> uppdrag kommer att
                skickas till Mathias Frick för godkännande.
                <br />
                <ToggleableBareAssignmentsList
                  assignments={data?.invalidAssignments ?? []}
                />
              </div>
            )}

            {placeholdersWithMissingExpensesCount > 0 && (
              <div>
                <b>{placeholdersWithMissingExpensesCount}</b> utfyllnadsuppdrag
                saknar förväntade kostnader och kommer inte att skickas ut.
                <br />
                <ToggleableBareAssignmentsList
                  assignments={
                    data?.placeholderAssignmentsWithMissingExpenses ?? []
                  }
                />
              </div>
            )}

            <br />
            <p>Är du säker på att du vill fortsätta?</p>
          </Wrapper>
        );

      default:
        return null;
    }
  };

  const getButtons = () => {
    if (sendPlannedAssignmentsToFieldTestersStatus === 'loading') {
      return [];
    }

    // if (totalAssignmentsToSend) {
    return [
      {
        label: 'Avbryt',
        onClick: () => onCancel(),
      },
      {
        icon: <FontAwesomeIcon icon={faPaperPlane} />,
        label: 'Skicka',
        onClick: handleSendToFieldTesters,
      },
    ];
    // }

    // return [
    //   {
    //     label: 'Ok',
    //     onClick: () => onCancel(),
    //   },
    // ];
  };

  return (
    <Modal buttons={getButtons()} title="Skicka ut till fälttestare">
      {getContent()}
    </Modal>
  );
};

export default SendCasesToFieldTestersModal;
