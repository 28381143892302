import styled from 'styled-components';

export interface TextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  unpadded?: boolean;
  disabled?: boolean;
  strikeThrough?: boolean;
  hoverAnimation?: boolean;
}

const TextButton = styled.button<TextButtonProps>`
  margin: 0;
  padding: ${({ unpadded }) => (unpadded ? 0 : 5)}px;

  border: 0;
  background: transparent;
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.background.tertiary
      : theme.colors.background.button};
  font: inherit;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${({ strikeThrough }) =>
    strikeThrough &&
    `
    text-decoration: line-through;
  `}
  white-space: nowrap;

  ${({ hoverAnimation, theme }) =>
    hoverAnimation &&
    `
    // blurry hover effect
    &:hover {
      color: ${theme.colors.foreground.primary};
    }
  `}
`;

export default TextButton;
