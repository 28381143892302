import {
  faQuestionCircle,
  IconDefinition,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBusAlt,
  faCarAlt,
  faFerry,
  faPlane,
  faTaxi,
  faTrain,
} from '@fortawesome/free-solid-svg-icons';
import { AssignmentTypeEnum, AssignmentViewModel, UserModel } from 'api/model';
import { IAddress } from 'utils/address';
import { makeDateComparator } from 'utils/sorting';

const getPlaceholderIcon = (type: AssignmentTypeEnum): IconDefinition => {
  switch (type) {
    case AssignmentTypeEnum.CarPlaceholder:
      return faQuestionCircle;
    case AssignmentTypeEnum.GoByBoat:
      return faFerry;
    case AssignmentTypeEnum.GoByBus:
      return faBusAlt;
    case AssignmentTypeEnum.GoByPlane:
      return faPlane;
    case AssignmentTypeEnum.GoByTrain:
      return faTrain;
    case AssignmentTypeEnum.GoByTaxi:
      return faTaxi;
    default:
      return faCarAlt;
  }
};

type AssignmentDetails = {
  placeholderStartTime: Date;
  placeHolderToAddress: IAddress;
  assignedToUser?: UserModel;
  estimatedStartOfLastAssignment?: Date;
};

export const getLastAssignmentDetails = (
  bookedAssignmentsArray: AssignmentViewModel[]
): AssignmentDetails | undefined => {
  if (bookedAssignmentsArray.length === 0) {
    return undefined; // Explicitly return undefined
  }

  const bookedAssignments = bookedAssignmentsArray.sort(
    makeDateComparator((a) => a.assignment.estimatedStartTime ?? new Date())
  );

  const lastCaseOfDay = bookedAssignments[bookedAssignments.length - 1];

  if (lastCaseOfDay.assignment.estimatedDuration === undefined) {
    return undefined; // Explicitly return undefined
  }

  const estimatedStartOfLastAssignment =
    lastCaseOfDay.assignment.estimatedStartTime ??
    lastCaseOfDay.assignment.startTime;

  if (estimatedStartOfLastAssignment === undefined) {
    return undefined; // Explicitly return undefined
  }

  const estimatedDurationOfLastAssignment =
    lastCaseOfDay.assignment.estimatedDuration;

  const placeholderStartTime = new Date(
    estimatedStartOfLastAssignment.getTime() + estimatedDurationOfLastAssignment
  );

  // Set from address details to the last assignment's to address details
  const placeHolderToAddress: IAddress = {
    name: lastCaseOfDay.assignment.toName,
    address: lastCaseOfDay.assignment.toAddress,
    city: lastCaseOfDay.assignment.toCity,
    zip: lastCaseOfDay.assignment.toZip,
    county: lastCaseOfDay.assignment.toCounty,
  };

  const assignedToUser = lastCaseOfDay.assignment.assignedTo;

  const result: AssignmentDetails = {
    placeholderStartTime,
    placeHolderToAddress,
    estimatedStartOfLastAssignment,
    assignedToUser,
  };

  return result;
};
export default getPlaceholderIcon;
