/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AssignmentModel,
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
  AssignmentViewModel,
  CarKeyPhotoRequest,
  CarParkPhotoRequest,
  CaseAddCarKeyPhotoParams,
  CaseAddCaseAssignmentPhotoParams,
  CaseAddCaseAssignmentPositionsParams,
  CaseAddOrUpdateCommentParams,
  CaseApproveCaseRequestParams,
  CaseAssignmentEventModel,
  CaseAssignmentGPSData,
  CaseCloseCaseParams,
  CaseDeleteCaseParams,
  CaseDeleteCommentParams,
  CaseDenyCaseRequestParams,
  CaseExtendedModel,
  CaseGetAssignmentOverlapsFromIds200Five,
  CaseGetAssignmentOverlapsFromIds200Four,
  CaseGetAssignmentOverlapsFromIds200One,
  CaseGetAssignmentOverlapsFromIds200Three,
  CaseGetAssignmentOverlapsFromIds200Two,
  CaseGetCaseParams,
  CaseGetCasesByRideOrderIdParams,
  CaseGetOpenAssignmentsParams,
  CaseMakeCaseAssignmentRequestParams,
  CaseModel,
  CaseModelBody,
  CaseUpdateCaseParams,
  GeolocationModel,
} from '.././model';
import useCaseCreateCaseMutator from '.././mutator/custom-instance';
import useCaseUpdateCaseMutator from '.././mutator/custom-instance';
import useCaseGetCaseOverlapsMutator from '.././mutator/custom-instance';
import useCaseGetAssignmentOverlapsFromIdsMutator from '.././mutator/custom-instance';
import useCaseGetAssignmentOverlapsMutator from '.././mutator/custom-instance';
import useCaseGetCasesByRideOrderIdMutator from '.././mutator/custom-instance';
import useCaseGetCaseMutator from '.././mutator/custom-instance';
import useCaseGetOpenAssignmentsMutator from '.././mutator/custom-instance';
import useCaseAddOrUpdateCommentMutator from '.././mutator/custom-instance';
import useCaseMarkEventsAsReadByAdminMutator from '.././mutator/custom-instance';
import useCaseAddCaseAssignmentPositionsMutator from '.././mutator/custom-instance';
import useCaseAddCaseAssignmentGPSDataMutator from '.././mutator/custom-instance';
import useCaseAddCaseAssignmentPhotoMutator from '.././mutator/custom-instance';
import useCaseAddCarKeyPhotoMutator from '.././mutator/custom-instance';
import useCaseAddCarKeyPhotoWithGeolocationMutator from '.././mutator/custom-instance';
import useCaseAddCarParkPhotoWithGeolocationMutator from '.././mutator/custom-instance';
import useCaseDeleteCaseMutator from '.././mutator/custom-instance';
import useCaseDeleteCommentMutator from '.././mutator/custom-instance';
import useCaseCloseCaseMutator from '.././mutator/custom-instance';
import useCaseMakeCaseAssignmentRequestMutator from '.././mutator/custom-instance';
import useCaseGetCaseAssignmentRequestsCountMutator from '.././mutator/custom-instance';
import useCaseGetCaseAssignmentRequestsMutator from '.././mutator/custom-instance';
import useCaseDenyCaseRequestMutator from '.././mutator/custom-instance';
import useCaseApproveCaseRequestMutator from '.././mutator/custom-instance';

export const useCaseCreateCaseHook = () => {
  const caseCreateCase = useCaseCreateCaseMutator<number>();

  return (caseModelBody: CaseModelBody) => {
    return caseCreateCase({
      url: `/api/Case/CreateCase`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: caseModelBody,
    });
  };
};

export const useCaseCreateCaseMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseCreateCaseHook>>>,
    TError,
    { data: CaseModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseCreateCaseHook>>>,
  TError,
  { data: CaseModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseCreateCase = useCaseCreateCaseHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseCreateCaseHook>>>,
    { data: CaseModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return caseCreateCase(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseCreateCaseMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseCreateCaseHook>>>
>;
export type CaseCreateCaseMutationBody = CaseModelBody;
export type CaseCreateCaseMutationError = unknown;

export const useCaseCreateCase = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseCreateCaseHook>>>,
    TError,
    { data: CaseModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseCreateCaseHook>>>,
  TError,
  { data: CaseModelBody },
  TContext
> => {
  const mutationOptions = useCaseCreateCaseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseUpdateCaseHook = () => {
  const caseUpdateCase = useCaseUpdateCaseMutator<void>();

  return (caseModelBody: CaseModelBody, params?: CaseUpdateCaseParams) => {
    return caseUpdateCase({
      url: `/api/Case/UpdateCase`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: caseModelBody,
      params,
    });
  };
};

export const useCaseUpdateCaseMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseUpdateCaseHook>>>,
    TError,
    { data: CaseModelBody; params?: CaseUpdateCaseParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseUpdateCaseHook>>>,
  TError,
  { data: CaseModelBody; params?: CaseUpdateCaseParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseUpdateCase = useCaseUpdateCaseHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseUpdateCaseHook>>>,
    { data: CaseModelBody; params?: CaseUpdateCaseParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return caseUpdateCase(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseUpdateCaseMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseUpdateCaseHook>>>
>;
export type CaseUpdateCaseMutationBody = CaseModelBody;
export type CaseUpdateCaseMutationError = unknown;

export const useCaseUpdateCase = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseUpdateCaseHook>>>,
    TError,
    { data: CaseModelBody; params?: CaseUpdateCaseParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseUpdateCaseHook>>>,
  TError,
  { data: CaseModelBody; params?: CaseUpdateCaseParams },
  TContext
> => {
  const mutationOptions = useCaseUpdateCaseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseGetCaseOverlapsHook = () => {
  const caseGetCaseOverlaps = useCaseGetCaseOverlapsMutator<CaseModel[]>();

  return (caseModelBody: CaseModelBody) => {
    return caseGetCaseOverlaps({
      url: `/api/Case/GetCaseOverlaps`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: caseModelBody,
    });
  };
};

export const useCaseGetCaseOverlapsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCaseOverlapsHook>>>,
    TError,
    { data: CaseModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCaseOverlapsHook>>>,
  TError,
  { data: CaseModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseGetCaseOverlaps = useCaseGetCaseOverlapsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCaseOverlapsHook>>>,
    { data: CaseModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return caseGetCaseOverlaps(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseGetCaseOverlapsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCaseOverlapsHook>>>
>;
export type CaseGetCaseOverlapsMutationBody = CaseModelBody;
export type CaseGetCaseOverlapsMutationError = unknown;

export const useCaseGetCaseOverlaps = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCaseOverlapsHook>>>,
    TError,
    { data: CaseModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCaseOverlapsHook>>>,
  TError,
  { data: CaseModelBody },
  TContext
> => {
  const mutationOptions = useCaseGetCaseOverlapsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseGetAssignmentOverlapsFromIdsHook = () => {
  const caseGetAssignmentOverlapsFromIds =
    useCaseGetAssignmentOverlapsFromIdsMutator<
      | CaseGetAssignmentOverlapsFromIds200One
      | CaseGetAssignmentOverlapsFromIds200Two
      | CaseGetAssignmentOverlapsFromIds200Three
      | CaseGetAssignmentOverlapsFromIds200Four
      | CaseGetAssignmentOverlapsFromIds200Five
    >();

  return (
    assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody
  ) => {
    return caseGetAssignmentOverlapsFromIds({
      url: `/api/Case/GetAssignmentOverlapsFromIds`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
    });
  };
};

export const useCaseGetAssignmentOverlapsFromIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsFromIdsHook>>
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsFromIdsHook>>
  >,
  TError,
  { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseGetAssignmentOverlapsFromIds =
    useCaseGetAssignmentOverlapsFromIdsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsFromIdsHook>>
    >,
    { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody }
  > = (props) => {
    const { data } = props ?? {};

    return caseGetAssignmentOverlapsFromIds(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseGetAssignmentOverlapsFromIdsMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsFromIdsHook>>
  >
>;
export type CaseGetAssignmentOverlapsFromIdsMutationBody =
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
export type CaseGetAssignmentOverlapsFromIdsMutationError = unknown;

export const useCaseGetAssignmentOverlapsFromIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsFromIdsHook>>
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsFromIdsHook>>
  >,
  TError,
  { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody },
  TContext
> => {
  const mutationOptions =
    useCaseGetAssignmentOverlapsFromIdsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseGetAssignmentOverlapsHook = () => {
  const caseGetAssignmentOverlaps =
    useCaseGetAssignmentOverlapsMutator<AssignmentViewModel[]>();

  return (assignmentModel: AssignmentModel) => {
    return caseGetAssignmentOverlaps({
      url: `/api/Case/GetAssignmentOverlaps`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentModel,
    });
  };
};

export const useCaseGetAssignmentOverlapsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsHook>>>,
    TError,
    { data: AssignmentModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsHook>>>,
  TError,
  { data: AssignmentModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseGetAssignmentOverlaps = useCaseGetAssignmentOverlapsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsHook>>>,
    { data: AssignmentModel }
  > = (props) => {
    const { data } = props ?? {};

    return caseGetAssignmentOverlaps(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseGetAssignmentOverlapsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsHook>>>
>;
export type CaseGetAssignmentOverlapsMutationBody = AssignmentModel;
export type CaseGetAssignmentOverlapsMutationError = unknown;

export const useCaseGetAssignmentOverlaps = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsHook>>>,
    TError,
    { data: AssignmentModel },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseGetAssignmentOverlapsHook>>>,
  TError,
  { data: AssignmentModel },
  TContext
> => {
  const mutationOptions = useCaseGetAssignmentOverlapsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseGetCasesByRideOrderIdHook = () => {
  const caseGetCasesByRideOrderId =
    useCaseGetCasesByRideOrderIdMutator<CaseModel[]>();

  return (params?: CaseGetCasesByRideOrderIdParams) => {
    return caseGetCasesByRideOrderId({
      url: `/api/Case/GetCasesByRideOrderId`,
      method: 'POST',
      params,
    });
  };
};

export const useCaseGetCasesByRideOrderIdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCasesByRideOrderIdHook>>>,
    TError,
    { params?: CaseGetCasesByRideOrderIdParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCasesByRideOrderIdHook>>>,
  TError,
  { params?: CaseGetCasesByRideOrderIdParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseGetCasesByRideOrderId = useCaseGetCasesByRideOrderIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCasesByRideOrderIdHook>>>,
    { params?: CaseGetCasesByRideOrderIdParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseGetCasesByRideOrderId(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseGetCasesByRideOrderIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCasesByRideOrderIdHook>>>
>;

export type CaseGetCasesByRideOrderIdMutationError = unknown;

export const useCaseGetCasesByRideOrderId = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCasesByRideOrderIdHook>>>,
    TError,
    { params?: CaseGetCasesByRideOrderIdParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCasesByRideOrderIdHook>>>,
  TError,
  { params?: CaseGetCasesByRideOrderIdParams },
  TContext
> => {
  const mutationOptions = useCaseGetCasesByRideOrderIdMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseGetCaseHook = () => {
  const caseGetCase = useCaseGetCaseMutator<CaseExtendedModel>();

  return (params?: CaseGetCaseParams) => {
    return caseGetCase({ url: `/api/Case/GetCase`, method: 'POST', params });
  };
};

export const useCaseGetCaseMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCaseHook>>>,
    TError,
    { params?: CaseGetCaseParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCaseHook>>>,
  TError,
  { params?: CaseGetCaseParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseGetCase = useCaseGetCaseHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCaseHook>>>,
    { params?: CaseGetCaseParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseGetCase(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseGetCaseMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCaseHook>>>
>;

export type CaseGetCaseMutationError = unknown;

export const useCaseGetCase = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCaseHook>>>,
    TError,
    { params?: CaseGetCaseParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCaseHook>>>,
  TError,
  { params?: CaseGetCaseParams },
  TContext
> => {
  const mutationOptions = useCaseGetCaseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseGetOpenAssignmentsHook = () => {
  const caseGetOpenAssignments = useCaseGetOpenAssignmentsMutator<number>();

  return (params?: CaseGetOpenAssignmentsParams, signal?: AbortSignal) => {
    return caseGetOpenAssignments({
      url: `/api/Case/GetOpenAssignments`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getCaseGetOpenAssignmentsQueryKey = (
  params?: CaseGetOpenAssignmentsParams
) => {
  return [`/api/Case/GetOpenAssignments`, ...(params ? [params] : [])] as const;
};

export const useCaseGetOpenAssignmentsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useCaseGetOpenAssignmentsHook>>>,
  TError = unknown,
>(
  params?: CaseGetOpenAssignmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useCaseGetOpenAssignmentsHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCaseGetOpenAssignmentsQueryKey(params);

  const caseGetOpenAssignments = useCaseGetOpenAssignmentsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseGetOpenAssignmentsHook>>>
  > = ({ signal }) => caseGetOpenAssignments(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseGetOpenAssignmentsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CaseGetOpenAssignmentsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseGetOpenAssignmentsHook>>>
>;
export type CaseGetOpenAssignmentsQueryError = unknown;

export const useCaseGetOpenAssignments = <
  TData = Awaited<ReturnType<ReturnType<typeof useCaseGetOpenAssignmentsHook>>>,
  TError = unknown,
>(
  params?: CaseGetOpenAssignmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useCaseGetOpenAssignmentsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useCaseGetOpenAssignmentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useCaseAddOrUpdateCommentHook = () => {
  const caseAddOrUpdateComment = useCaseAddOrUpdateCommentMutator<void>();

  return (params?: CaseAddOrUpdateCommentParams) => {
    return caseAddOrUpdateComment({
      url: `/api/Case/AddOrUpdateComment`,
      method: 'POST',
      params,
    });
  };
};

export const useCaseAddOrUpdateCommentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseAddOrUpdateCommentHook>>>,
    TError,
    { params?: CaseAddOrUpdateCommentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseAddOrUpdateCommentHook>>>,
  TError,
  { params?: CaseAddOrUpdateCommentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseAddOrUpdateComment = useCaseAddOrUpdateCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseAddOrUpdateCommentHook>>>,
    { params?: CaseAddOrUpdateCommentParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseAddOrUpdateComment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseAddOrUpdateCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseAddOrUpdateCommentHook>>>
>;

export type CaseAddOrUpdateCommentMutationError = unknown;

export const useCaseAddOrUpdateComment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseAddOrUpdateCommentHook>>>,
    TError,
    { params?: CaseAddOrUpdateCommentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseAddOrUpdateCommentHook>>>,
  TError,
  { params?: CaseAddOrUpdateCommentParams },
  TContext
> => {
  const mutationOptions = useCaseAddOrUpdateCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseMarkEventsAsReadByAdminHook = () => {
  const caseMarkEventsAsReadByAdmin =
    useCaseMarkEventsAsReadByAdminMutator<void>();

  return (
    assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody
  ) => {
    return caseMarkEventsAsReadByAdmin({
      url: `/api/Case/MarkEventsAsReadByAdmin`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
    });
  };
};

export const useCaseMarkEventsAsReadByAdminMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseMarkEventsAsReadByAdminHook>>>,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseMarkEventsAsReadByAdminHook>>>,
  TError,
  { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseMarkEventsAsReadByAdmin = useCaseMarkEventsAsReadByAdminHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseMarkEventsAsReadByAdminHook>>>,
    { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody }
  > = (props) => {
    const { data } = props ?? {};

    return caseMarkEventsAsReadByAdmin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseMarkEventsAsReadByAdminMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseMarkEventsAsReadByAdminHook>>>
>;
export type CaseMarkEventsAsReadByAdminMutationBody =
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
export type CaseMarkEventsAsReadByAdminMutationError = unknown;

export const useCaseMarkEventsAsReadByAdmin = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseMarkEventsAsReadByAdminHook>>>,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseMarkEventsAsReadByAdminHook>>>,
  TError,
  { data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody },
  TContext
> => {
  const mutationOptions =
    useCaseMarkEventsAsReadByAdminMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const useCaseAddCaseAssignmentPositionsHook = () => {
  const caseAddCaseAssignmentPositions =
    useCaseAddCaseAssignmentPositionsMutator<boolean>();

  return (
    geolocationModel: GeolocationModel[],
    params?: CaseAddCaseAssignmentPositionsParams
  ) => {
    return caseAddCaseAssignmentPositions({
      url: `/api/Case/AddCaseAssignmentPositions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: geolocationModel,
      params,
    });
  };
};

export const useCaseAddCaseAssignmentPositionsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPositionsHook>>
    >,
    TError,
    { data: GeolocationModel[]; params?: CaseAddCaseAssignmentPositionsParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPositionsHook>>>,
  TError,
  { data: GeolocationModel[]; params?: CaseAddCaseAssignmentPositionsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseAddCaseAssignmentPositions =
    useCaseAddCaseAssignmentPositionsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPositionsHook>>
    >,
    { data: GeolocationModel[]; params?: CaseAddCaseAssignmentPositionsParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return caseAddCaseAssignmentPositions(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseAddCaseAssignmentPositionsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPositionsHook>>>
>;
export type CaseAddCaseAssignmentPositionsMutationBody = GeolocationModel[];
export type CaseAddCaseAssignmentPositionsMutationError = unknown;

/**
 * @deprecated
 */
export const useCaseAddCaseAssignmentPositions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPositionsHook>>
    >,
    TError,
    { data: GeolocationModel[]; params?: CaseAddCaseAssignmentPositionsParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPositionsHook>>>,
  TError,
  { data: GeolocationModel[]; params?: CaseAddCaseAssignmentPositionsParams },
  TContext
> => {
  const mutationOptions =
    useCaseAddCaseAssignmentPositionsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseAddCaseAssignmentGPSDataHook = () => {
  const caseAddCaseAssignmentGPSData =
    useCaseAddCaseAssignmentGPSDataMutator<boolean>();

  return (caseAssignmentGPSData: CaseAssignmentGPSData[]) => {
    return caseAddCaseAssignmentGPSData({
      url: `/api/Case/AddCaseAssignmentGPSData`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: caseAssignmentGPSData,
    });
  };
};

export const useCaseAddCaseAssignmentGPSDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentGPSDataHook>>>,
    TError,
    { data: CaseAssignmentGPSData[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentGPSDataHook>>>,
  TError,
  { data: CaseAssignmentGPSData[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseAddCaseAssignmentGPSData = useCaseAddCaseAssignmentGPSDataHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentGPSDataHook>>>,
    { data: CaseAssignmentGPSData[] }
  > = (props) => {
    const { data } = props ?? {};

    return caseAddCaseAssignmentGPSData(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseAddCaseAssignmentGPSDataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentGPSDataHook>>>
>;
export type CaseAddCaseAssignmentGPSDataMutationBody = CaseAssignmentGPSData[];
export type CaseAddCaseAssignmentGPSDataMutationError = unknown;

export const useCaseAddCaseAssignmentGPSData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentGPSDataHook>>>,
    TError,
    { data: CaseAssignmentGPSData[] },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentGPSDataHook>>>,
  TError,
  { data: CaseAssignmentGPSData[] },
  TContext
> => {
  const mutationOptions =
    useCaseAddCaseAssignmentGPSDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseAddCaseAssignmentPhotoHook = () => {
  const caseAddCaseAssignmentPhoto =
    useCaseAddCaseAssignmentPhotoMutator<void>();

  return (params?: CaseAddCaseAssignmentPhotoParams) => {
    return caseAddCaseAssignmentPhoto({
      url: `/api/Case/AddCaseAssignmentPhoto`,
      method: 'POST',
      params,
    });
  };
};

export const useCaseAddCaseAssignmentPhotoMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPhotoHook>>>,
    TError,
    { params?: CaseAddCaseAssignmentPhotoParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPhotoHook>>>,
  TError,
  { params?: CaseAddCaseAssignmentPhotoParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseAddCaseAssignmentPhoto = useCaseAddCaseAssignmentPhotoHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPhotoHook>>>,
    { params?: CaseAddCaseAssignmentPhotoParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseAddCaseAssignmentPhoto(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseAddCaseAssignmentPhotoMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPhotoHook>>>
>;

export type CaseAddCaseAssignmentPhotoMutationError = unknown;

export const useCaseAddCaseAssignmentPhoto = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPhotoHook>>>,
    TError,
    { params?: CaseAddCaseAssignmentPhotoParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCaseAssignmentPhotoHook>>>,
  TError,
  { params?: CaseAddCaseAssignmentPhotoParams },
  TContext
> => {
  const mutationOptions = useCaseAddCaseAssignmentPhotoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseAddCarKeyPhotoHook = () => {
  const caseAddCarKeyPhoto = useCaseAddCarKeyPhotoMutator<void>();

  return (params?: CaseAddCarKeyPhotoParams) => {
    return caseAddCarKeyPhoto({
      url: `/api/Case/AddCarKeyPhoto`,
      method: 'POST',
      params,
    });
  };
};

export const useCaseAddCarKeyPhotoMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoHook>>>,
    TError,
    { params?: CaseAddCarKeyPhotoParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoHook>>>,
  TError,
  { params?: CaseAddCarKeyPhotoParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseAddCarKeyPhoto = useCaseAddCarKeyPhotoHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoHook>>>,
    { params?: CaseAddCarKeyPhotoParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseAddCarKeyPhoto(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseAddCarKeyPhotoMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoHook>>>
>;

export type CaseAddCarKeyPhotoMutationError = unknown;

export const useCaseAddCarKeyPhoto = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoHook>>>,
    TError,
    { params?: CaseAddCarKeyPhotoParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoHook>>>,
  TError,
  { params?: CaseAddCarKeyPhotoParams },
  TContext
> => {
  const mutationOptions = useCaseAddCarKeyPhotoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseAddCarKeyPhotoWithGeolocationHook = () => {
  const caseAddCarKeyPhotoWithGeolocation =
    useCaseAddCarKeyPhotoWithGeolocationMutator<void>();

  return (carKeyPhotoRequest: CarKeyPhotoRequest) => {
    return caseAddCarKeyPhotoWithGeolocation({
      url: `/api/Case/AddCarKeyPhotoWithGeolocation`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: carKeyPhotoRequest,
    });
  };
};

export const useCaseAddCarKeyPhotoWithGeolocationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoWithGeolocationHook>>
    >,
    TError,
    { data: CarKeyPhotoRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoWithGeolocationHook>>
  >,
  TError,
  { data: CarKeyPhotoRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseAddCarKeyPhotoWithGeolocation =
    useCaseAddCarKeyPhotoWithGeolocationHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoWithGeolocationHook>>
    >,
    { data: CarKeyPhotoRequest }
  > = (props) => {
    const { data } = props ?? {};

    return caseAddCarKeyPhotoWithGeolocation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseAddCarKeyPhotoWithGeolocationMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoWithGeolocationHook>>
  >
>;
export type CaseAddCarKeyPhotoWithGeolocationMutationBody = CarKeyPhotoRequest;
export type CaseAddCarKeyPhotoWithGeolocationMutationError = unknown;

export const useCaseAddCarKeyPhotoWithGeolocation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoWithGeolocationHook>>
    >,
    TError,
    { data: CarKeyPhotoRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useCaseAddCarKeyPhotoWithGeolocationHook>>
  >,
  TError,
  { data: CarKeyPhotoRequest },
  TContext
> => {
  const mutationOptions =
    useCaseAddCarKeyPhotoWithGeolocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseAddCarParkPhotoWithGeolocationHook = () => {
  const caseAddCarParkPhotoWithGeolocation =
    useCaseAddCarParkPhotoWithGeolocationMutator<void>();

  return (carParkPhotoRequest: CarParkPhotoRequest) => {
    return caseAddCarParkPhotoWithGeolocation({
      url: `/api/Case/AddCarParkPhotoWithGeolocation`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: carParkPhotoRequest,
    });
  };
};

export const useCaseAddCarParkPhotoWithGeolocationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseAddCarParkPhotoWithGeolocationHook>>
    >,
    TError,
    { data: CarParkPhotoRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useCaseAddCarParkPhotoWithGeolocationHook>>
  >,
  TError,
  { data: CarParkPhotoRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseAddCarParkPhotoWithGeolocation =
    useCaseAddCarParkPhotoWithGeolocationHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useCaseAddCarParkPhotoWithGeolocationHook>>
    >,
    { data: CarParkPhotoRequest }
  > = (props) => {
    const { data } = props ?? {};

    return caseAddCarParkPhotoWithGeolocation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseAddCarParkPhotoWithGeolocationMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useCaseAddCarParkPhotoWithGeolocationHook>>
  >
>;
export type CaseAddCarParkPhotoWithGeolocationMutationBody =
  CarParkPhotoRequest;
export type CaseAddCarParkPhotoWithGeolocationMutationError = unknown;

export const useCaseAddCarParkPhotoWithGeolocation = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseAddCarParkPhotoWithGeolocationHook>>
    >,
    TError,
    { data: CarParkPhotoRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useCaseAddCarParkPhotoWithGeolocationHook>>
  >,
  TError,
  { data: CarParkPhotoRequest },
  TContext
> => {
  const mutationOptions =
    useCaseAddCarParkPhotoWithGeolocationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseDeleteCaseHook = () => {
  const caseDeleteCase = useCaseDeleteCaseMutator<void>();

  return (params?: CaseDeleteCaseParams) => {
    return caseDeleteCase({
      url: `/api/Case/DeleteCase`,
      method: 'DELETE',
      params,
    });
  };
};

export const useCaseDeleteCaseMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseDeleteCaseHook>>>,
    TError,
    { params?: CaseDeleteCaseParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseDeleteCaseHook>>>,
  TError,
  { params?: CaseDeleteCaseParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseDeleteCase = useCaseDeleteCaseHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseDeleteCaseHook>>>,
    { params?: CaseDeleteCaseParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseDeleteCase(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseDeleteCaseMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseDeleteCaseHook>>>
>;

export type CaseDeleteCaseMutationError = unknown;

export const useCaseDeleteCase = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseDeleteCaseHook>>>,
    TError,
    { params?: CaseDeleteCaseParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseDeleteCaseHook>>>,
  TError,
  { params?: CaseDeleteCaseParams },
  TContext
> => {
  const mutationOptions = useCaseDeleteCaseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseDeleteCommentHook = () => {
  const caseDeleteComment = useCaseDeleteCommentMutator<void>();

  return (params?: CaseDeleteCommentParams) => {
    return caseDeleteComment({
      url: `/api/Case/DeleteComment`,
      method: 'DELETE',
      params,
    });
  };
};

export const useCaseDeleteCommentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseDeleteCommentHook>>>,
    TError,
    { params?: CaseDeleteCommentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseDeleteCommentHook>>>,
  TError,
  { params?: CaseDeleteCommentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseDeleteComment = useCaseDeleteCommentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseDeleteCommentHook>>>,
    { params?: CaseDeleteCommentParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseDeleteComment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseDeleteCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseDeleteCommentHook>>>
>;

export type CaseDeleteCommentMutationError = unknown;

export const useCaseDeleteComment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseDeleteCommentHook>>>,
    TError,
    { params?: CaseDeleteCommentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseDeleteCommentHook>>>,
  TError,
  { params?: CaseDeleteCommentParams },
  TContext
> => {
  const mutationOptions = useCaseDeleteCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseCloseCaseHook = () => {
  const caseCloseCase = useCaseCloseCaseMutator<void>();

  return (params?: CaseCloseCaseParams) => {
    return caseCloseCase({
      url: `/api/Case/CloseCase`,
      method: 'POST',
      params,
    });
  };
};

export const useCaseCloseCaseMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseCloseCaseHook>>>,
    TError,
    { params?: CaseCloseCaseParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseCloseCaseHook>>>,
  TError,
  { params?: CaseCloseCaseParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseCloseCase = useCaseCloseCaseHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseCloseCaseHook>>>,
    { params?: CaseCloseCaseParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseCloseCase(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseCloseCaseMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseCloseCaseHook>>>
>;

export type CaseCloseCaseMutationError = unknown;

export const useCaseCloseCase = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseCloseCaseHook>>>,
    TError,
    { params?: CaseCloseCaseParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseCloseCaseHook>>>,
  TError,
  { params?: CaseCloseCaseParams },
  TContext
> => {
  const mutationOptions = useCaseCloseCaseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseMakeCaseAssignmentRequestHook = () => {
  const caseMakeCaseAssignmentRequest =
    useCaseMakeCaseAssignmentRequestMutator<Blob>();

  return (
    geolocationModel: GeolocationModel,
    params?: CaseMakeCaseAssignmentRequestParams
  ) => {
    return caseMakeCaseAssignmentRequest({
      url: `/api/Case/MakeCaseAssignmentRequest`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: geolocationModel,
      params,
      responseType: 'blob',
    });
  };
};

export const useCaseMakeCaseAssignmentRequestMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseMakeCaseAssignmentRequestHook>>
    >,
    TError,
    { data: GeolocationModel; params?: CaseMakeCaseAssignmentRequestParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseMakeCaseAssignmentRequestHook>>>,
  TError,
  { data: GeolocationModel; params?: CaseMakeCaseAssignmentRequestParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseMakeCaseAssignmentRequest = useCaseMakeCaseAssignmentRequestHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useCaseMakeCaseAssignmentRequestHook>>
    >,
    { data: GeolocationModel; params?: CaseMakeCaseAssignmentRequestParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return caseMakeCaseAssignmentRequest(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseMakeCaseAssignmentRequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseMakeCaseAssignmentRequestHook>>>
>;
export type CaseMakeCaseAssignmentRequestMutationBody = GeolocationModel;
export type CaseMakeCaseAssignmentRequestMutationError = unknown;

export const useCaseMakeCaseAssignmentRequest = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseMakeCaseAssignmentRequestHook>>
    >,
    TError,
    { data: GeolocationModel; params?: CaseMakeCaseAssignmentRequestParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseMakeCaseAssignmentRequestHook>>>,
  TError,
  { data: GeolocationModel; params?: CaseMakeCaseAssignmentRequestParams },
  TContext
> => {
  const mutationOptions =
    useCaseMakeCaseAssignmentRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseGetCaseAssignmentRequestsCountHook = () => {
  const caseGetCaseAssignmentRequestsCount =
    useCaseGetCaseAssignmentRequestsCountMutator<number>();

  return (signal?: AbortSignal) => {
    return caseGetCaseAssignmentRequestsCount({
      url: `/api/Case/GetCaseAssignmentRequestsCount`,
      method: 'GET',
      signal,
    });
  };
};

export const getCaseGetCaseAssignmentRequestsCountQueryKey = () => {
  return [`/api/Case/GetCaseAssignmentRequestsCount`] as const;
};

export const useCaseGetCaseAssignmentRequestsCountQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsCountHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsCountHook>>
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCaseGetCaseAssignmentRequestsCountQueryKey();

  const caseGetCaseAssignmentRequestsCount =
    useCaseGetCaseAssignmentRequestsCountHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsCountHook>>
    >
  > = ({ signal }) => caseGetCaseAssignmentRequestsCount(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsCountHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CaseGetCaseAssignmentRequestsCountQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsCountHook>>
  >
>;
export type CaseGetCaseAssignmentRequestsCountQueryError = unknown;

export const useCaseGetCaseAssignmentRequestsCount = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsCountHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsCountHook>>
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useCaseGetCaseAssignmentRequestsCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useCaseGetCaseAssignmentRequestsHook = () => {
  const caseGetCaseAssignmentRequests =
    useCaseGetCaseAssignmentRequestsMutator<CaseAssignmentEventModel[]>();

  return (signal?: AbortSignal) => {
    return caseGetCaseAssignmentRequests({
      url: `/api/Case/GetCaseAssignmentRequests`,
      method: 'GET',
      signal,
    });
  };
};

export const getCaseGetCaseAssignmentRequestsQueryKey = () => {
  return [`/api/Case/GetCaseAssignmentRequests`] as const;
};

export const useCaseGetCaseAssignmentRequestsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsHook>>
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCaseGetCaseAssignmentRequestsQueryKey();

  const caseGetCaseAssignmentRequests = useCaseGetCaseAssignmentRequestsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsHook>>>
  > = ({ signal }) => caseGetCaseAssignmentRequests(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CaseGetCaseAssignmentRequestsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsHook>>>
>;
export type CaseGetCaseAssignmentRequestsQueryError = unknown;

export const useCaseGetCaseAssignmentRequests = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useCaseGetCaseAssignmentRequestsHook>>
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useCaseGetCaseAssignmentRequestsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useCaseDenyCaseRequestHook = () => {
  const caseDenyCaseRequest = useCaseDenyCaseRequestMutator<Blob>();

  return (params?: CaseDenyCaseRequestParams) => {
    return caseDenyCaseRequest({
      url: `/api/Case/DenyCaseRequest`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useCaseDenyCaseRequestMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseDenyCaseRequestHook>>>,
    TError,
    { params?: CaseDenyCaseRequestParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseDenyCaseRequestHook>>>,
  TError,
  { params?: CaseDenyCaseRequestParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseDenyCaseRequest = useCaseDenyCaseRequestHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseDenyCaseRequestHook>>>,
    { params?: CaseDenyCaseRequestParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseDenyCaseRequest(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseDenyCaseRequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseDenyCaseRequestHook>>>
>;

export type CaseDenyCaseRequestMutationError = unknown;

export const useCaseDenyCaseRequest = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseDenyCaseRequestHook>>>,
    TError,
    { params?: CaseDenyCaseRequestParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseDenyCaseRequestHook>>>,
  TError,
  { params?: CaseDenyCaseRequestParams },
  TContext
> => {
  const mutationOptions = useCaseDenyCaseRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCaseApproveCaseRequestHook = () => {
  const caseApproveCaseRequest = useCaseApproveCaseRequestMutator<Blob>();

  return (params?: CaseApproveCaseRequestParams) => {
    return caseApproveCaseRequest({
      url: `/api/Case/ApproveCaseRequest`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useCaseApproveCaseRequestMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseApproveCaseRequestHook>>>,
    TError,
    { params?: CaseApproveCaseRequestParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCaseApproveCaseRequestHook>>>,
  TError,
  { params?: CaseApproveCaseRequestParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const caseApproveCaseRequest = useCaseApproveCaseRequestHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCaseApproveCaseRequestHook>>>,
    { params?: CaseApproveCaseRequestParams }
  > = (props) => {
    const { params } = props ?? {};

    return caseApproveCaseRequest(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CaseApproveCaseRequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCaseApproveCaseRequestHook>>>
>;

export type CaseApproveCaseRequestMutationError = unknown;

export const useCaseApproveCaseRequest = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCaseApproveCaseRequestHook>>>,
    TError,
    { params?: CaseApproveCaseRequestParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCaseApproveCaseRequestHook>>>,
  TError,
  { params?: CaseApproveCaseRequestParams },
  TContext
> => {
  const mutationOptions = useCaseApproveCaseRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
