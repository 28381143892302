import {
  faChevronDown,
  faChevronUp,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BareCaseAssignmentWithTypeAndAssigneeNameModel } from 'api/model';
import TextButton from 'components/inputs/TextButton';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import useTranslations from 'contexts/basicData/useTranslations';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { makeStringComparator } from 'utils/sorting';

const Wrapper = styled.div`
  margin-bottom: 1rem;
  max-width: 500px;
  p {
    margin-bottom: 0.5rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 300px;
  overflow-y: auto;
  font-size: 0.9em;
`;

const StyledTextButton = styled(TextButton)`
  margin: 0;
`;

type Props = {
  assignments: BareCaseAssignmentWithTypeAndAssigneeNameModel[];
};
const ToggleableBareAssignmentsList: React.FC<Props> = ({ assignments }) => {
  const { assignmentTypes, caseTypes } = useTranslations();
  const [showAssignments, setShowAssignments] = useState(false);

  const columnSettings = useMemo((): ColumnSetting<
    BareCaseAssignmentWithTypeAndAssigneeNameModel,
    any
  >[] => {
    const columns: ColumnSetting<
      BareCaseAssignmentWithTypeAndAssigneeNameModel,
      any
    >[] = [
      {
        head: 'Ärendetyp',
        render: (model) => caseTypes[model.caseType],
        width: 100,
        sortFunction: makeStringComparator(
          (model) => caseTypes[model.caseType] ?? ''
        ),
      },
      {
        head: 'Uppdragstyp',
        render: (model) => assignmentTypes[model.assignmentType],
        width: 100,
        sortFunction: makeStringComparator(
          (model) => assignmentTypes[model.assignmentType] ?? ''
        ),
      },
      {
        head: 'Fälttestare',
        render: (model) => model.assigneeName,
        width: 100,
        sortFunction: makeStringComparator((model) => model.assigneeName ?? ''),
      },
      {
        head: 'Regnr',
        render: (model) => model.caseRegistrationNumber || '-',
        width: 100,
        sortFunction: makeStringComparator(
          (model) => model.caseRegistrationNumber ?? ''
        ),
      },
      {
        head: '',
        render: (model) => (
          <StyledTextButton
            onClick={() => {
              window.open(`/sok/${model.caseID}`, '_blank');
            }}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </StyledTextButton>
        ),
        width: 50,
      },
    ];
    return columns;
  }, [assignmentTypes, caseTypes]);

  return showAssignments ? (
    <Wrapper>
      <StyledTextButton onClick={() => setShowAssignments(false)}>
        Dölj <FontAwesomeIcon icon={faChevronUp} />
      </StyledTextButton>
      <Table
        columnSettings={columnSettings}
        rows={assignments}
        useColumnWidthAsFlexAmount
      />
    </Wrapper>
  ) : (
    <StyledTextButton onClick={() => setShowAssignments(true)}>
      Visa <FontAwesomeIcon icon={faChevronDown} />
    </StyledTextButton>
  );
};

export default ToggleableBareAssignmentsList;
