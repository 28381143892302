/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type WorkGearType = (typeof WorkGearType)[keyof typeof WorkGearType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkGearType = {
  OBDMeter: 0,
  GreenPlate: 1,
  TyreGauge: 2,
  WorkClothes: 3,
} as const;
