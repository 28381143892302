import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { IAddress } from 'utils/address';

interface IAddressWithTitle extends IAddress, PropsWithChildren {
  title?: string;
  className?: string;
}
const Wrapper = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${({ error }) => (error ? 'red' : 'black')};
`;

const Address: React.FC<IAddressWithTitle> = ({
  name,
  address,
  zip,
  city,
  county,
  title,
  className,
  children,
}) => {
  return (
    <Wrapper className={className}>
      {title && <strong>{title}</strong>}
      <span>{name}</span>
      <span>{address}</span>
      <span>
        {zip} {city}
      </span>
      {county && <span>{county.areaName}</span>}
      {children}
    </Wrapper>
  );
};

export default Address;
