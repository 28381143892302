/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type GlobalSettingEnum =
  (typeof GlobalSettingEnum)[keyof typeof GlobalSettingEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GlobalSettingEnum = {
  AutoApprovalEnabled: 1,
  ConsecutiveHoursOfRestLast24Hours: 2,
  ConsecutiveHoursOfRestLast7Days: 3,
  MaxDrivingHoursLast7DaysAveragePer4Weeks: 4,
  MarginBetweenAssignmentsInMinutesWhenBooking: 5,
  DaysAheadToSpecifyFTAvailability: 6,
  MinDurationBetweenAssignmentArrivedAndTestComplete: 7,
  ZakraCustomerSurveyExpiryHours: 8,
  MaxDistanceFromGoalLocationInKms: 9,
  EstimatedStartTimePassedThreshold: 10,
  EstimatedEndTimePassedThreshold: 11,
  BookedToTimePassedThreshold: 12,
  UsersWithAccessToPlaceholderAssignments: 13,
} as const;
